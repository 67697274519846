import styled, { css } from "styled-components";

import { Modal as ModalAnt, Table as TableAnt, Button as ButtonAnt } from "antd";


export const Container = styled(ModalAnt)``;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled(TableAnt)`
  padding: 0.2rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-25);

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    padding: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
`;
export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const ButtonCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30%;
  height: 3.25rem;
  border-radius: 5px;

  @media only screen and (max-width: 500px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;