import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../services/api";
import { verifyPermission } from "../../services/auth";

import { CategoryModel } from "../../models/CategoryModel";
import { Product as ProductModel } from "../../models/Product";
import { Provider } from "../../models/Provider";

import PageContainer from "../../containers/PageContainer";
import ProductList from "../../containers/ProductList";
import ProductCategorization from "../../containers/ProductCategorization";

import ModalNewEditProduct from "../../components/ModalNewEditProduct";

import { Dropdown, Menu, notification } from "antd";

import {
  Container,
  TopSide,
  Content,
  AddIcon,
  Input,
  Col,
  Button,
  Select,
  ButtonFilter,
  IconFilter,
} from "./styles";
import { CheckboxMenu, CheckboxMenuGroup } from "../CashManagement/styles";

const { Option } = Select;

interface IProp extends RouteComponentProps {}

const filterProducts = [
  { id: 0, description: "Lojas" },
  { id: 1, description: "Pedidos" },
  { id: 2, description: "Compras" },
  { id: 3, description: "Produtos Removidos" },
];

const Products: React.FC<IProp> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldSearch, setShouldSearch] = useState<boolean>(true);
  const [visibleNewProduct, setVisibleNewProduct] = useState<boolean>(false);
  const [showProductTagPage, setShowProductTagPage] = useState<boolean>(false);
  const [providerSearch, setProviderSearch] = useState<boolean>(true);

  const [products, setProducts] = useState<ProductModel[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<
    ProductModel[] | undefined
  >(undefined);
  const [filteredCategory, setFilteredCategory] = useState<
    ProductModel[] | undefined
  >(undefined);

  const [productsSearch, setProductsSearch] = useState<string>("");

  const [categories, setCategories] = useState<CategoryModel[]>();

  const [providers, setProviders] = useState<Provider[]>([]);

  const [types, setTypes] = useState<number[]>([]);
  const [categorySearch, setCategorySearch] = useState<number>(0);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const {
          data: { content },
        } = await api.get("/certified_provider");

        setProviders(() => content);
      } catch (e) {
        notification.error({
          message: "Erro ao carregar fornecedores",
          duration: 5,
        });
      } finally {
        setProviderSearch(false);
      }
    };
    if (providerSearch) fetchProviders();
  }, [providerSearch]);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        let apiUrl = `/products?page=1`;

        if (types.some((type) => type === 0))
          apiUrl += "&permission_store=true";
        if (types.some((type) => type === 1))
          apiUrl += "&permission_order=true";
        if (types.some((type) => type === 2))
          apiUrl += "&permission_purchase=true";
        if (types.some((type) => type === 3)) {
          apiUrl += `&withDeleted=true`;
        } else {
          apiUrl += `&size=1000`;
        }

        const {
          data: {
            data: { content: products },
          },
        } = await api.get(apiUrl);
        setProducts(
          products.sort(
            (firstProduct, secondProduct) => firstProduct.id - secondProduct.id
          )
        );

        const {
          data: { data: productsCategories },
        } = await api.get("/product_categories");

        setCategories(productsCategories);

        setLoading(false);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        notification.error({ message: _message, duration: 5 });
      }
    };

    if (shouldSearch) {
      fetchProducts();
      setShouldSearch(false);
    }

    if (categorySearch) {
      const filteredProducts = products?.filter(
        (product) => product.category_id === categorySearch
      );
      if (productsSearch) {
        const filteredProducts = filteredCategory?.filter((product) =>
          product?.name
            ?.toLowerCase()
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.includes(
              productsSearch
                ?.toLowerCase()
                ?.normalize("NFD")
                ?.replace(/[\u0300-\u036f]/g, "")
            )
        );
        setFilteredCategory(filteredProducts);
      } else {
        setFilteredCategory(filteredProducts);
      }
    } else {
      setFilteredCategory(undefined);
    }

    if (productsSearch) {
      const filteredProducts = products?.filter((product) =>
        product?.name
          ?.toLowerCase()
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.includes(
            productsSearch
              ?.toLowerCase()
              ?.normalize("NFD")
              ?.replace(/[\u0300-\u036f]/g, "")
          )
      );
      setFilteredProducts(filteredProducts);
    } else {
      setFilteredProducts(undefined);
    }
  }, [shouldSearch, productsSearch, categorySearch, products, types]);

  const findProduct = ({ target: { value } }) => {
    setProductsSearch(value);
  };
  const findCategory = (value) => {
    setCategorySearch(value);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckboxMenuGroup
          onChange={(type) => {
            setTypes(type as number[]);
            setShouldSearch(true);
          }}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {filterProducts.map((type) => (
            <>
              <CheckboxMenu key={type.id} value={type.id}>
                {type.description}
              </CheckboxMenu>
            </>
          ))}
        </CheckboxMenuGroup>
      </Menu.Item>
    </Menu>
  );

  return (
    <PageContainer route="Produtos">
      {showProductTagPage ? (
        <ProductCategorization setShowProductTagPage={setShowProductTagPage} />
      ) : (
        <Container>
          <TopSide gutter={4}>
            <Col xs={10} sm={4} lg={4}>
              <Select
                loading={loading}
                placeholder={"Buscar por categoria"}
                onChange={(value) => findCategory(value as number)}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                optionFilterProp="children"
              >
                {categories?.map((category) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xs={10} sm={8} lg={4}>
              <Input
                placeholder="Digite o nome do produto"
                onChange={findProduct}
              />
            </Col>
            <Col xs={1}>
              <Dropdown
                trigger={["click"]}
                placement="bottomCenter"
                overlay={menu}
              >
                <ButtonFilter disabled={shouldSearch}>
                  <IconFilter />
                </ButtonFilter>
              </Dropdown>
            </Col>
            {verifyPermission("product.classification") && (
              <Col xs={2} sm={3}>
                <ButtonFilter
                  disabled={shouldSearch}
                  onClick={() => setShowProductTagPage(true)}
                >
                  Categorizar Produtos
                </ButtonFilter>
              </Col>
            )}

            <Col sm={5}>
              {verifyPermission("product.add") && (
                <Button onClick={() => setVisibleNewProduct(true)}>
                  Clique para adicionar um produto
                  <AddIcon />
                </Button>
              )}
            </Col>
          </TopSide>
          <Content>
            <ProductList
              setShouldSearch={setShouldSearch}
              loading={loading}
              setLoading={setLoading}
              products={filteredCategory || filteredProducts || products}
              categories={categories}
              providers={providers}
              setProviderSearch={setProviderSearch}
            />
          </Content>
          <ModalNewEditProduct
            visible={visibleNewProduct}
            setVisible={setVisibleNewProduct}
            setShouldSearch={setShouldSearch}
            productEdit={null}
            categories={categories}
            providers={providers}
            setProviderSearch={setProviderSearch}
          ></ModalNewEditProduct>
        </Container>
      )}
    </PageContainer>
  );
};

export default withRouter(Products);
