import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../../services/api";

import { Order as OrderModel } from "../../../models/Order/Order";
import { DeliveryRouteStatus } from "../../../models/enums/DeliveryRoutes";

import moment from "moment";
import { notification } from "antd";

import {
  Center,
  Container,
  MoreIcon,
  Table,
  ReturnIcon,
  HeaderOrder,
} from "./styles";

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const ModalMovement: React.FC<IProps> = ({ order, setVisible, visible }) => {
  const [step, setStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [select, setSelect] = useState(null);
  const [auditDataSource, setAuditDataSource] = useState([]);

  useEffect(() => {
    const fetchAudit = async () => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await api.get(`/delivery-route-audit/${select}`);
        setAuditDataSource(data);
      } catch (error) {
        notification.error({
          message: "Erro ao buscar informações de auditoria",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };
    if (select) {
      fetchAudit();
    }
  }, [select]);

  const getStatus = (status) => {
    if (status === DeliveryRouteStatus.INDISPONIVEL) {
      return "Entrega Indisponível";
    }
    if (
      status === DeliveryRouteStatus.ATRIBUIDA ||
      status === DeliveryRouteStatus.ROTEIRIZADA ||
      status === DeliveryRouteStatus.CRIADA
    ) {
      return "Entrega em Preparação";
    }
    if (status === DeliveryRouteStatus.EM_ROTA) {
      return "Em Transporte";
    }
    if (status === DeliveryRouteStatus.EM_ROTA_ATE_VOCE) {
      return "Em Rota de Entega até você";
    }
    if (status === DeliveryRouteStatus.FINALIZADA) {
      return "Entrega Realizada com Sucesso";
    }
    if (status === DeliveryRouteStatus.EM_PAUSA) {
      return "Transporte em Pausa";
    }
    return "Aguardando dados de entrega";
  };

  const getStatusAudit = (status) => {
    if (status === DeliveryRouteStatus.CRIADA) {
      return "Rota Criada";
    }
    if (status === DeliveryRouteStatus.ATRIBUIDA) {
      return "Rota Atribuida ao motorista";
    }
    if (status === DeliveryRouteStatus.ROTEIRIZADA) {
      return "Rota Roteirizada";
    }
    if (status === DeliveryRouteStatus.EM_ROTA) {
      return "Em Transporte";
    }
    if (status === DeliveryRouteStatus.FINALIZADA) {
      return "Entrega Realizada com Sucesso";
    }
    if (status === DeliveryRouteStatus.EM_PAUSA) {
      return "Transporte em Pausa";
    }
    if (status === DeliveryRouteStatus.CANCELADA) {
      return "Cancelada";
    }
  };

  const getTypeCargo = (cargoType: number) => {
    if (cargoType === 0) {
      return "Carga Gelada";
    } else if (cargoType === 1) {
      return "Carga Seca";
    } else if (cargoType === 2) {
      return "Fornecedores Homologados";
    } else {
      return "Carga Mista";
    }
  };

  const columnsRoute = [
    {
      title: "Rota",
      dataIndex: "id",
      key: "id",
      render: (text) => <>{text}</>,
    },
    {
      title: "Tipo de Carga",
      dataIndex: "cargo_type",
      key: "cargo_type",
      render: (text) => <>{getTypeCargo(text)}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <>{getStatus(text)}</>,
    },

    {
      title: <Center>Detalhes</Center>,
      render: (_, record) => (
        <Center>
          <MoreIcon
            onClick={() => {
              setSelect(record.id);
              setStep(2);
            }}
          />
        </Center>
      ),
    },
  ];

  const columnsAudit = [
    {
      title: "Data de lançamento",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <>{moment(
        moment.utc(text, "YYYY-MM-DD HH:mm:ss").toISOString()
      ).format("DD-MM-YYYY HH:mm:ss")}</>,
    },
    {
      title: "Status",
      dataIndex: "new_value",
      key: "new_value",
      render: (text) => <>{getStatusAudit(+text)}</>,
    },
  ];

  return (
    <Container
      title={`Movimentação de Entrega`}
      visible={visible}
      centered
      width={600}
      footer={null}
      onCancel={() => {
        setVisible(false);
        setStep(1);
      }}
    >
      <HeaderOrder>
        <h2>Pedido Nº {order?.vhsys}</h2>
        {step === 2 && <ReturnIcon onClick={() => setStep(1)} />}
      </HeaderOrder>
      {step === 1 ? (
        <Table
          columns={columnsRoute}
          pagination={false}
          dataSource={order?.deliveryRoutes}
          rowKey={(entity: any) => entity.id}
        />
      ) : (
        <Table
          loading={loading}
          columns={columnsAudit}
          pagination={false}
          dataSource={auditDataSource}
          rowKey={(entity: any) => entity.id}
        />
      )}
    </Container>
  );
};

export default ModalMovement;
