import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import api from "../../services/api";

import InputMask from "../InputMask";

import { Form, Input, message, notification } from "antd";

import { Modal, Col, Container, Row, Select } from "./styles";
import { getCepInformations } from "../../services/cep";
import { Provider } from "../../models/Provider";
import MonetaryInput from "../MonetaryInput2";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedProvider?: Provider;
  setSelectedProvider: Dispatch<SetStateAction<Provider | undefined>>;
  setProviderSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalOrderProvider: React.FC<IProps> = ({
  visible,
  setVisible,
  selectedProvider,
  setSelectedProvider,
  setProviderSearch,
}) => {
  const [loading, setLoading] = useState(false);
  const [typeOf, setTypeOf] = useState(1);
  const [cpfCnpj, setCpfCnpj] = useState<{
    cpf: string | undefined;
    cnpj: string | undefined;
  }>({ cpf: "", cnpj: "" });

  const [providerForm] = Form.useForm();
  const [minOrderAmount, setMinOrderAmount] = useState<number | null>();

  useEffect(() => {
    const setProviderValues = async () => {
      try {
        setLoading(true);

        setTypeOf(selectedProvider?.type_person === 0 ? 0 : 1);
        setCpfCnpj({
          cpf: selectedProvider?.cpf,
          cnpj: selectedProvider?.cnpj,
        });
        setMinOrderAmount(selectedProvider?.min_order_amount);
        await providerForm.setFieldsValue({
          cnpj: selectedProvider?.cnpj,
          company_name: selectedProvider?.company_name,
          fantasy_name: selectedProvider?.fantasy_name,
          cpf: selectedProvider?.cpf,
          state_registration: selectedProvider?.state_registration,
          name_contact: selectedProvider?.name_contact,
          number_contact: selectedProvider?.number_contact,
          cep: selectedProvider?.cep,
          address: selectedProvider?.address,
          number: selectedProvider?.number,
          district: selectedProvider?.district,
          city: selectedProvider?.city,
          uf: selectedProvider?.uf,
          type_person:
            selectedProvider?.type_person !== 0 ? "JURÍDICA" : "FÍSICA",
        });
      } catch (e) {
        notification.error({
          message: "Erro ao encontrar fornecedor",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };
    if (selectedProvider) {
      setProviderValues();
    } else {
      clearFields();
    }
  }, [selectedProvider, providerForm]);

  const clearFields = async () => {
    await providerForm.resetFields();
    setCpfCnpj({ cpf: "", cnpj: "" });
    setMinOrderAmount(null);
  };

  const handleState = (event, field) => {
    if (field === "cnpj") {
      setCpfCnpj((oldValues) => ({
        ...oldValues,
        cnpj: event.target.value,
      }));
    } else {
      setCpfCnpj((oldValues) => ({
        ...oldValues,
        cpf: event.target.value,
      }));
    }
  };

  const handleCepChange = async (value) => {
    if (value && value.length === 9) {
      try {
        const { city, address, state_registration } = await getCepInformations(
          value.replace(/\D/g, "")
        );
        providerForm.setFieldsValue({
          address,
          district: "",
          city,
          uf: state_registration,
        });
      } catch (error) {
        message.error("Erro ao buscar informações do CEP.");
      }
    }
  };

  const validateField = async (): Promise<boolean> => {
    try {
      await providerForm.validateFields();
      return true;
    } catch {
      message.warning("Preencha todos os campos corretamente.");
      return false;
    }
  };

  const handleFinish = async () => {
    const validated = await validateField();
    if (!validated) {
      return;
    }
    try {
      const method = selectedProvider ? "put" : "post";
      const url = selectedProvider
        ? `/certified_provider/${selectedProvider.id}`
        : "/certified_provider";
      setLoading(true);

      const {
        company_name,
        fantasy_name,
        uf,
        state_registration,
        city,
        district,
        number,
        address,
        cep,
        number_contact,
        name_contact,
      } = providerForm.getFieldsValue();
      await api[method](url, {
        cnpj: typeOf === 1 ? cpfCnpj?.cnpj?.replace(/[^0-9]+/g, "") : null,
        company_name: company_name?.toLowerCase() || "",
        fantasy_name: fantasy_name?.toLowerCase() || "",
        uf: uf?.toLowerCase() || "",
        cpf: typeOf !== 1 ? cpfCnpj?.cpf?.replace(/[^0-9]+/g, "") : null,
        state_registration: state_registration?.toLowerCase() || "",
        city: city?.toLowerCase() || "",
        district: district?.toLowerCase() || "",
        number: +number,
        address: address?.toLowerCase() || "",
        cep: cep?.toLowerCase() || "",
        number_contact: number_contact?.toLowerCase() || "",
        name_contact: name_contact?.toLowerCase() || "",
        type_person: typeOf,
        min_order_amount: minOrderAmount,
      });

      message.success("Fornecedor salvo com sucesso.");

      clearFields();
      setSelectedProvider(undefined);
      setProviderSearch(true);
      setVisible(false);
    } catch (error) {
      const _message = "Houve um erro ao criar fornecedor";
      //@ts-ignore
      const _description = error?.response?.data?.message || "";
      notification.error({
        message: _message,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={loading}
      title="Fornecedor Homologado"
      centered
      visible={visible}
      onOk={() => handleFinish()}
      onCancel={() => setVisible(false)}
      cancelText="Cancelar"
      okText="Adicionar"
      destroyOnClose={true}
      width={window.outerWidth > 768 ? "40%" : "100%"}
    >
      <Form layout="vertical" form={providerForm}>
        <Container>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Tipo de Pessoa"
                name="type_person"
                key="key"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  placeholder="Selecione uma opção"
                  onChange={(value) => {
                    setTypeOf(+value);
                  }}
                >
                  <Select.Option value={0} key={0}>
                    Física
                  </Select.Option>
                  <Select.Option value={1} key={1}>
                    Jurídica
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {typeOf === 1 ? (
              <Col sm={24} xs={24}>
                <Form.Item
                  label={
                    <span>
                      CNPJ{" "}
                      <span style={{ color: "var(--rainbows-outer-rim)" }}>
                        *
                      </span>
                    </span>
                  }
                  name="cnpj"
                  rules={[
                    {
                      validator: (_, value) => {
                        return value?.replace(/[^0-9]+/g, "")?.length > 13
                          ? Promise.resolve()
                          : Promise.reject("CNPJ inválido");
                      },
                    },
                  ]}
                >
                  <InputMask
                    disabled={loading}
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    onChange={(event) => {
                      handleState(event, "cnpj");
                      providerForm.setFields([
                        {
                          name: "cnpj",
                          errors: [],
                        },
                      ]);
                    }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col sm={24} xs={24}>
                <Form.Item
                  label={
                    <span>
                      CPF{" "}
                      <span style={{ color: "var(--rainbows-outer-rim)" }}>
                        *
                      </span>
                    </span>
                  }
                  name="cpf"
                  rules={[
                    {
                      validator: (_, value) => {
                        return value?.replace(/[^0-9]+/g, "")?.length > 10
                          ? Promise.resolve()
                          : Promise.reject("CPF inválido");
                      },
                    },
                  ]}
                >
                  <InputMask
                    disabled={loading}
                    placeholder="CPF"
                    mask="999.999.999-99"
                    name="cpf"
                    onChange={(event) => {
                      handleState(event, "cpf");
                      providerForm.setFields([
                        {
                          name: "cpf",
                          errors: [],
                        },
                      ]);
                    }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={10}>
            <Col sm={18} xs={24}>
              <Form.Item
                label="Nome do Fornecedor"
                name="company_name"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Input
                  disabled={loading}
                  placeholder="Nome do Fornecedor"
                  name="company_name"
                />
              </Form.Item>
            </Col>
            <Col sm={6} xs={24}>
              <Form.Item
                label="Valor Mínimo para Pedido"
                name="min_order_amount"
              >
                <MonetaryInput
                  disabled={loading}
                  defaultValue={minOrderAmount}
                  getValue={(value) => setMinOrderAmount(+value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col sm={12} xs={24}>
              <Form.Item
                label="Nome do Fantasia"
                name="fantasy_name"
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira o nome do fantasia!",
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  placeholder="Nome do Fantasia"
                  name="fantasy_name"
                />
              </Form.Item>
            </Col>

            <Col sm={12} xs={24}>
              <Form.Item label="Inscrição Estadual" name="state_registration">
                <Input
                  disabled={loading}
                  placeholder="Inscrição Estadual"
                  name="state_registration"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col sm={12} xs={24}>
              <Form.Item label="Nome do Contato" name="name_contact">
                <Input
                  disabled={loading}
                  placeholder="Nome do Contato"
                  name="name_contact"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item label="Número do Contato" name="number_contact">
                <InputMask
                  disabled={loading}
                  placeholder="Número do Contato"
                  mask="(99) 99999-9999"
                  name="number_contact"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col sm={24} xs={24}>
              <Form.Item label="CEP" name="cep">
                <Input
                  disabled={loading}
                  placeholder="CEP"
                  name="cep"
                  value={providerForm.getFieldValue("cep")}
                  onChange={(e) => handleCepChange(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={8} xs={24}>
              <Form.Item label="Rua" name="address">
                <Input
                  disabled={loading}
                  placeholder="Rua do Fornecedor"
                  name="address"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item label="Número" name="number">
                <Input disabled={loading} placeholder="Número" name="number" />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item label="Bairro" name="district">
                <Input
                  disabled={loading}
                  placeholder="Bairro"
                  name="district"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item label="Cidade" name="city">
                <Input disabled={loading} placeholder="Cidade" name="city" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Estado" name="uf">
                <Input disabled={loading} placeholder="Estado" name="uf" />
              </Form.Item>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal>
  );
};

export default ModalOrderProvider;
