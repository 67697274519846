import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "../../../services/api";

import { currencyFormater } from "../../../services/currencyFormater";

import { Order as OrderModel } from "../../../models/Order/Order";

import Centralizer from "../../../containers/Centralizer";

import MonetaryInput from "../../../components/MonetaryInput2";

import { Form, notification, Spin } from "antd";

import {
  Container,
  ButtonCancel,
  ButtonSave,
  Col,
  Modal,
  Row,
  Footer,
  Input,
} from "./styles";

const { TextArea } = Input;

interface IItem {
  id?: number;
  category_id: number;
  category_name: string;
  product_id: number;
  price_sell: number;
  name: string;
  upload_url: string;
  quantity: number;
  weight: number;
  unity: string;
}

interface ITotals {
  total: number;
  totalNF: number;
  productTotal: number;
  serviceTotal: number;
}

interface IPresetValues {
  discount: number;
  discount_product: number;
  value_icms_st: number;
  value_ipi: number;
  value_interest: number;
  value_freight: number;
  value_additional_taxes: number;
  additional_value: number;
  comment: string;
}

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  orderSelect: OrderModel | undefined | null;
  setOrderSelect: Dispatch<SetStateAction<OrderModel | null | undefined>>;
  ordersToUpdate: OrderModel[];
  setOrdersToUpdate: Dispatch<SetStateAction<OrderModel[]>>;
}

const ModalTaxesPayments: React.FC<IProps> = ({
  setVisible,
  visible,
  orderSelect,
  setOrderSelect,
  ordersToUpdate,
  setOrdersToUpdate,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFinish, setLoadingFinish] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderModel>();

  const [storeFreight, setStoreFreight] = useState<number>(0);

  const [totals, setTotals] = useState<ITotals>({
    total: 0,
    totalNF: 0,
    productTotal: 0,
    serviceTotal: 0,
  });

  const [taxesValues, setTaxesValues] = useState<IPresetValues>({
    discount: 0,
    discount_product: 0,
    value_icms_st: 0,
    value_ipi: 0,
    value_interest: 0,
    value_freight: 0,
    value_additional_taxes: 0,
    additional_value: 0,
    comment: "",
  });

  const productPercentage = (+ordersToUpdate[0]?.product_percentage || 0) ?? null;

  const calculateServicePercentage = () => {
    return productPercentage !== null ? 1 - productPercentage : null;
  };

  const servicePercentage = calculateServicePercentage() ?? 0;

  useEffect(() => {
    if (orderSelect && orderSelect.store_id && visible) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const {
            data: {
              result: { freight },
            },
          } = await api.get(`/value_freight/${orderSelect.store_id}`);

          setStoreFreight(freight);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;
          notification.error({
            message: "Oops! Erro ao listar o frete.",
            description: error
              ? _description
              : "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde!",
            duration: 5,
          });
        }
      };
      fetchData();
    }
  }, [orderSelect, visible]);

  useEffect(() => {
    const orderValues = async () => {
      setLoading(true);
      try {
        const {
          data: { content },
        } = await api.get(`/orders?vhsys=${orderSelect?.vhsys}`);
        let _order = content[0];

        setTaxesValues(() => ({
          discount: +(_order?.discount || 0) || 0,
          discount_product: +(_order?.discount_product || 0) || 0,
          value_icms_st: +(_order?.value_icms_st || 0) || 0,
          value_ipi: +(_order?.value_ipi || 0) || 0,
          value_freight: +(_order?.value_freight || 0) || 0,
          value_interest: +(_order?.value_interest || 0) || 0,
          value_additional_taxes: +(_order?.value_additional_taxes || 0) || 0,
          additional_value: +(_order?.additional_value || 0) || 0,
          comment: _order?.comment || "",
        }));
        setOrder(_order);
      } catch (error) {
        //@ts-ignore
        const _description = error.message || "";
        notification.error({
          message: "Oops! Erro ao localizar o pedido.",
          description: _description
            ? _description
            : "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde!",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };

    const resetFields = () => {
      setTaxesValues({
        discount: 0,
        discount_product: 0,
        value_icms_st: 0,
        value_ipi: 0,
        value_interest: 0,
        value_freight: 0,
        value_additional_taxes: 0,
        additional_value: 0,
        comment: "",
      });
      setTotals({
        total: 0,
        totalNF: 0,
        productTotal: 0,
        serviceTotal: 0,
      });
      setOrderSelect(null);
      setOrder(undefined);
      setStoreFreight(0);
    };
    if (visible) {
      orderValues();
    } else {
      resetFields();
    }
  }, [visible, orderSelect]);

  useEffect(() => {
    if (storeFreight && order) {
      let _totalOrderValue =
        order?.orderItems?.reduce(
          (totalPrice, reducePrice) =>
            totalPrice +
            +(+(reducePrice?.price_unit || 0) * (+reducePrice?.quantity || 0)),
          0
        ) || 0;

      let freightOrderValue =
        (_totalOrderValue * (productPercentage) - taxesValues.discount_product) *
        (+storeFreight / 100);
      setTaxesValues((oldValues) => ({
        ...oldValues,
        value_freight: freightOrderValue,
      }));
    }
  }, [taxesValues.discount_product]);

  useEffect(() => {
    const calcTotals = () => {
      let _totalOrderValue =
        order?.orderItems?.reduce(
          (totalPrice, reducePrice) =>
            totalPrice +
            +(+(reducePrice?.price_unit || 0) * (+reducePrice?.quantity || 0)),
          0
        ) || 0;

      let _totalProducts =
        _totalOrderValue * (productPercentage) - +(taxesValues.discount_product || 0);

      let _totalService = taxesValues.discount_product
        ? (_totalOrderValue * (productPercentage) - +(taxesValues.discount_product || 0)) *
          0.334
        : _totalOrderValue * servicePercentage;

      let _total =
        _totalOrderValue * (productPercentage) +
        (order?.to_delivery === "true" ? 0 : +taxesValues.value_freight) +
        (+taxesValues.value_icms_st || 0) +
        (+taxesValues.value_ipi || 0) +
        (+taxesValues.value_additional_taxes || 0) +
        (+taxesValues.value_interest || 0) +
        (+taxesValues.additional_value || 0) -
        +(+taxesValues.discount + taxesValues.discount_product || 0) +
        (taxesValues.discount_product
          ? (_totalOrderValue * (productPercentage) - +(taxesValues.discount_product || 0)) *
            0.334
          : _totalOrderValue * servicePercentage);

      let _totalNF =
        _totalOrderValue * (productPercentage) +
        (order?.to_delivery === "true" ? 0 : +taxesValues.value_freight) +
        (+taxesValues.value_icms_st || 0) +
        (+taxesValues.value_ipi || 0) +
        (+taxesValues.value_additional_taxes || 0) +
        (+taxesValues.value_interest || 0) +
        (+taxesValues.additional_value || 0) -
        +(+taxesValues.discount + taxesValues.discount_product || 0);

      if (0) {
        return notification.warning({
          message:
            "Não é possível realizar um desconto maior que os valores do pedido",
          duration: 5,
        });
      }

      setTotals(() => ({
        total: _total,
        totalNF: _totalNF,
        productTotal: _totalProducts,
        serviceTotal: _totalService,
      }));
    };

    if (visible) {
      calcTotals();
    }
  }, [taxesValues, order]);

  const changeField = (field: string, value: number | string) => {
    setTaxesValues((oldValues) => ({ ...oldValues, [field]: value }));
  };

  const payloadContruct = async () => {
    let _orderItems = order?.orderItems?.map((item) => ({
      product_id: item.product_id,
      products: {
        name: item?.products?.name,
      },
      quantity: +item.quantity,
      price_unit: item?.price_unit?.toString(),
      id: item.id,
    }));

    return {
      user_id: order?.user_id,
      store_id: order?.store_id,
      orderItems: _orderItems,
      status: order?.status,
      to_delivery: order?.to_delivery,
      comment: taxesValues.comment,
      total: +totals.total.toFixed(2),
      total_nf: +(totals.totalNF || 0).toFixed(2),
      additional_value: taxesValues.additional_value,
      value_icms_st: taxesValues.value_icms_st,
      value_ipi: taxesValues.value_ipi,
      value_additional_taxes: taxesValues.value_additional_taxes,
      value_interest: taxesValues.value_interest,
      discount: taxesValues.discount,
      discount_product: +taxesValues.discount_product || 0,
      franchise_tax: +totals.serviceTotal.toFixed(2),
      value_freight: taxesValues.value_freight,
    };
  };

  const onFinish = async () => {
    const payload = await payloadContruct();
    if (
      (taxesValues.discount || taxesValues.discount_product) &&
      !taxesValues.comment
    ) {
      return notification.warning({
        message: "Existem valores de descontos nesse pedido",
        description: "Para continuar informe o motivo do desconto adicionado",
        duration: 5,
      });
    }

    if (
      +payload.total_nf + +payload.discount + +payload.discount_product <
      +payload.discount + +payload.discount_product
    ) {
      return notification.warning({
        message: `Ops! Não é possível adicionar o desconto neste pedido.`,
        description: `O valor do desconto não deve ser maior que o valor total`,
        duration: 5,
      });
    }

    const _totalPayment =
      orderSelect?.order_payments?.reduce(
        (totalPrice, reducePrice) =>
          totalPrice + +(+(reducePrice?.payment_value || 0)),
        0
      ) || 0;

    if (_totalPayment > payload.total_nf) {
      return notification.warning({
        message: `O valor pago ultrapassa o valor total da NF`,
        description: `O valor total da NF não deve ser menor que o valor já pago no pedido`,
        duration: 5,
      });
    }

    setLoadingFinish(true);
    try {
      if (order) {
        await api.put(`/orders/${order.id}`, {
          ...payload,
        });

        let _orderToUpdateFind = [...ordersToUpdate];
        let findIndex = _orderToUpdateFind.findIndex(
          (_order) => _order.id === order.id
        );

        _orderToUpdateFind[findIndex] = {
          ..._orderToUpdateFind[findIndex],
          comment: taxesValues.comment,
          total: totals.total.toFixed(2),
          total_nf: (totals.totalNF || 0).toFixed(2),
          additional_value: taxesValues.additional_value,
          value_icms_st: taxesValues.value_icms_st,
          value_ipi: taxesValues.value_ipi,
          value_additional_taxes: taxesValues.value_additional_taxes,
          value_interest: taxesValues.value_interest,
          discount: taxesValues.discount,
          discount_product: +(taxesValues.discount_product || 0),
          franchise_tax: +totals.serviceTotal.toFixed(2),
          value_freight: taxesValues.value_freight,
        };

        setOrdersToUpdate(() => [..._orderToUpdateFind]);
      }

      notification.success({
        message: "Pedido atualizado com sucesso",
        duration: 5,
      });
    } catch (e) {
      //@ts-ignore
      const _description = e.message;

      notification.error({
        message: `Erro ao atualizar pedido`,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoadingFinish(false);
      setVisible(false);
    }
  };

  return (
    <Modal
      title={`Atualização de valores do pedido ${orderSelect?.vhsys}`}
      visible={visible}
      centered
      destroyOnClose={true}
      closable={false}
      width={1000}
      footer={[
        <ButtonCancel onClick={() => setVisible(false)}>Cancelar</ButtonCancel>,
        <ButtonSave
          loading={loading || loadingFinish}
          onClick={() => onFinish()}
        >
          Atualizar Pedido
        </ButtonSave>,
      ]}
    >
      {loading ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <Container>
          {loading ? (
            <Centralizer>
              <Spin />
            </Centralizer>
          ) : (
            <>
              <Form layout="vertical" initialValues={{ remember: false }}>
                <Row gutter={[4, 4]}>
                  <Col sm={4}>
                    <Form.Item label="Desconto Total:" name="discount">
                      <MonetaryInput
                        defaultValue={taxesValues.discount}
                        getValue={(value) => {
                          changeField("discount", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item
                      label="Desconto de Produtos:"
                      name="discount_product"
                    >
                      <MonetaryInput
                        defaultValue={taxesValues.discount_product}
                        getValue={(value) => {
                          changeField("discount_product", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item label="Valor ICMS ST:" name="value_icms_st">
                      <MonetaryInput
                        defaultValue={taxesValues.value_icms_st}
                        getValue={(value) => {
                          changeField("value_icms_st", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item label="Valor IPI:" name="value_ipi">
                      <MonetaryInput
                        defaultValue={taxesValues.value_ipi}
                        getValue={(value) => {
                          changeField("value_ipi", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item label="Valor Juros:" name="value_interest">
                      <MonetaryInput
                        defaultValue={taxesValues.value_interest}
                        getValue={(value) => {
                          changeField("value_interest", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item
                      label="Valor Demais Impostos:"
                      name="value_additional_taxes"
                    >
                      <MonetaryInput
                        defaultValue={taxesValues.value_additional_taxes}
                        getValue={(value) => {
                          changeField("value_additional_taxes", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[4, 4]}>
                  <Col sm={4}>
                    <Form.Item label="Valor adicional:" name="additional_value">
                      <MonetaryInput
                        defaultValue={taxesValues.additional_value}
                        getValue={(value) => {
                          changeField("additional_value", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item
                      label={`Valor de Frete ${storeFreight}%`}
                      name="value_freight"
                    >
                      <MonetaryInput
                        defaultValue={taxesValues?.value_freight}
                        getValue={(value) => {
                          changeField("value_freight", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item label="Comentário:" name="comment">
                      <TextArea
                        style={{ height: "3.25rem" }}
                        placeholder="Deixe algum comentário relacionado ao seu pedido"
                        rows={5}
                        onChange={({ target: { value } }) => {
                          changeField("comment", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Container>
      )}

      <Footer gutter={[4, 4]}>
        <Col sm={5} className="col-total">
          <span className="span-total">Total</span>
          <span className="span-total-result">
            R$ {currencyFormater(totals?.total || 0)}
          </span>
        </Col>
        <Col sm={5} className="col-total">
          <span className="span-total">Total NF</span>{" "}
          <span className="span-total-result">
            R$ {currencyFormater(totals?.totalNF || 0)}
          </span>
        </Col>
        <Col sm={5} className="col-total">
          <span className="span-total">Valor Produtos</span>{" "}
          <span className="span-total-result">
            R$ {currencyFormater(totals?.productTotal || 0)}
          </span>
        </Col>
        <Col sm={5} className="col-total">
          <span className="span-total">Valor Serviço</span>{" "}
          <span className="span-total-result">
            R$ {currencyFormater(totals?.serviceTotal || 0)}
          </span>
        </Col>
      </Footer>
    </Modal>
  );
};

export default ModalTaxesPayments;
