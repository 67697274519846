import styled from "styled-components";
import { Modal as ModalAnt } from "antd";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  p {
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    overflow-y: scroll;
  }
`;
