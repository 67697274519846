import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../../services/api";
import { currencyFormater } from "../../../services/currencyFormater";

import { Order, Order as OrderModel } from "../../../models/Order/Order";

import { notification } from "antd";

import {
  ButtonCancel,
  ButtonSave,
  Container,
  FooterModal,
  Table,
} from "./styles";
import { exportCSVFile } from "../../../services/exportCSVFile";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalOrderHomologCSV: React.FC<IProps> = ({
  visible,
  setVisible,
  setShouldSearch,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    const fetchOrdersHomolog = async () => {
      setLoading(true);
      try {
        const {
          data: { content },
        } = await api.get(`/orders?page=1&size=1000&status=0&type_of_load=2`);

        setOrders(content);
      } catch (error) {
        notification.error({
          message: "Erro ao buscar informações dos pedidos",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };
    if (visible) {
      fetchOrdersHomolog();
    } else {
      setOrders([]);
    }
  }, [visible]);

  const CSVExtract = () => {
    const headers = {
      vhsys: "VHSYS",
      company_name: "LOJA",
      product_name: "PRODUTO",
      quantity: "QUANTIDADE",
      total: "TOTAL",
    };

    const orderPayload = orders.map((order) => {
      const itemPayload = order?.orderItems?.map((orderItem) => ({
        vhsys: "---",
        company_name: "---",
        product_name: orderItem.products.name,
        quantity: +orderItem.quantity,
        total:
          "R$ " +
          currencyFormater(
            +orderItem.quantity * +(orderItem.products.price_sell || 0)
          ),
      }));

      const response = [
        {
          vhsys: order?.vhsys,
          company_name: order?.store?.company_name,
          product_name: "---",
          quantity: itemPayload.reduce(
            (total, item) => total + +item.quantity,
            0
          ),
          total: "R$ " + currencyFormater(+order?.total),
        },
        ...itemPayload,
      ];

      return response;
    });
    const response = [];
    orderPayload.forEach((payload) => {
      //@ts-ignore
      payload.forEach((_payload) => response.push(_payload));
    });

    exportCSVFile(headers, response, "pedidos");
  };

  const onFinish = async () => {
    setLoading(true);
    // await CSVExtract();
    try {
      await api.patch("/orders/update-status-orders", {
        orderIds: orders.map((_order) => _order.id),
        status: 1,
      });
      notification.success({
        message: "Pedidos atualizados com sucesso!",
        duration: 5,
      });
      setShouldSearch(true);
      setVisible(false);
    } catch (e) {
      notification.error({
        message: "Erro ao atualizar status dos pedidos",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Código VHSYS",
      dataIndex: "vhsys",
      key: "vhsys",
      render: (text) => <>{text}</>,
    },
    {
      title: "Loja",
      dataIndex: "store.company_name",
      key: "store.company_name",
      render: (_, record) => <>{record?.store?.company_name || "-"}</>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => <>{currencyFormater(+text)}</>,
    },
  ];

  return (
    <Container
      title={`Pedidos de Fornecedores Homologados`}
      visible={visible}
      centered
      width={600}
      footer={
        <FooterModal>
          <ButtonCancel onClick={() => setVisible(false)}>
            Cancelar
          </ButtonCancel>
          <ButtonSave
            onClick={() => {
              onFinish();
            }}
            loading={loading}
            disabled={!orders?.length}
          >
            Confirmar pedidos
          </ButtonSave>
        </FooterModal>
      }
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Table
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={orders}
        rowKey={(entity: any) => entity.id}
        scroll={{ y: 320 }}
      />
    </Container>
  );
};

export default ModalOrderHomologCSV;
