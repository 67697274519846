import React, { Dispatch, SetStateAction, useState } from "react";

import moment from "moment";

import api from "../../../services/api";
import { verifyPermission } from "../../../services/auth";
import { currencyFormater } from "../../../services/currencyFormater";
import { verifyStatusRoute } from "../../../services/verifyStatusRoute";

import { CategoryModel } from "../../../models/CategoryModel";
import { Order as OrderModel } from "../../../models/Order/Order";
import { OrderStatus } from "../../../models/enums/OrderStatus";
import { DeliveryRouteStatus } from "../../../models/enums/DeliveryRoutes";

import Centralizer from "../../../containers/Centralizer";

import Spinner from "../../../components/Spinner";
import BlingImport from "../../../assets/svg/blingimport.svg";
import BlingPartialImport from "../../../assets/svg/blingpartial.svg";
import TotsvImport from "../../../assets/svg/totvs-fill.svg";
import TotsvNotImport from "../../../assets/svg/totvs-line.svg";
import RoutechImport from "../../../assets/svg/routech-active.svg";
import RoutechNotImport from "../../../assets/svg/routech-inactive.svg";

import BlingReverse from "../../../assets/svg/blingreverse.svg";

import ModalStatusChange from "../ModalStatusChange";
import ModalAuditPayment from "../ModalAuditPayment";

import ModalPayment from "../ModalPayment";
import ModalMovement from "../ModalMovement";
import ModalBlingSend from "../ModalBlingSend";
import ModalInfoOrder from "../ModalInfoOrder";
import ModalRatingOrder from "../ModalRatingOrder";
import ModalImageOrder from "../ModalImageOrder";
import ModalUploadImageOrder from "../ModalUploadImageOrder";
import ModalHistoryOrderItens from "../ModalHistoryOrderItens";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../../hooks/useResponsiveSizeTable";

import { Modal, Tooltip, notification } from "antd";

import {
  Table,
  StatusInfo,
  MoreIcon,
  InfoIcon,
  StarIcon,
  ImageIcon,
  Center,
  StarEmpty,
  StarIconComment,
  Menu,
  Dropdown,
  BlingIcon,
  DeliveryIcon,
  PackageIcon,
  StatusDiv,
  FirstOrderIcon,
  TotvsIcon,
  RoutechIcon,
  DocumentsIcon,
} from "./styles";
import { useLocation } from "react-router-dom";
import { Provider } from "../../../models/Provider";
import ModalOrderDocuments from "../ModalOrderDocuments/index.";
interface IProps {
  orders: OrderModel[];
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  categories: CategoryModel[];
  setVisibleModalHomologOrder?: Dispatch<SetStateAction<boolean>>;
  setVisibleEdit: Dispatch<SetStateAction<boolean>>;
  setOrderToEdit: Dispatch<SetStateAction<OrderModel | undefined>>;
  isStoreActive: boolean | undefined;
  showCheckboxes?: boolean;
  onSelectedChange?: (orderIds: number[]) => void;
  selectedOrderIds?: number[];
  loadingBlingPY?: boolean | undefined;
  countryPY?: boolean;
  providers?: Provider[];
  servicePercentage?: number;
  productPercentage?: number | null;
}

const OrdersList: React.FC<IProps> = ({
  orders,
  setShouldSearch,
  categories,
  setVisibleEdit,
  setVisibleModalHomologOrder,
  setOrderToEdit,
  isStoreActive,
  showCheckboxes = false,
  onSelectedChange,
  selectedOrderIds,
  loadingBlingPY,
  countryPY,
  providers,
  productPercentage
}) => {
  const [orderSelect, setOrderSelect] = useState<OrderModel>();
  const [status, setStatus] = useState<number>(0);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [loadingBling, setLoadingBling] = useState<boolean>(false);
  const [orderBlingStatus, setOrderBlingStatus] = useState<OrderModel>();

  //modals
  const [visibleModalAuditPayment, setVisibleModalAuditPayment] =
    useState<boolean>(false);
  const [visibleModalHistoryOrderItens, setVisibleModalHistoryOrderItens] =
    useState<boolean>(false);
  const [visibleModalImage, setVisibleModalImage] = useState<boolean>(false);
  const [visibleModalInfo, setVisibleModalInfo] = useState<boolean>(false);
  const [visibleModalStatusChange, setVisibleModalStatusChange] =
    useState<boolean>(false);
  const [visibleModalPayment, setVisibleModalPayment] =
    useState<boolean>(false);
  const [visibleModalRating, setVisibleModalRating] = useState<boolean>(false);
  const [visibleModalMovement, setVisibleModalMovement] =
    useState<boolean>(false);
  const [visibleModalUploadImage, setVisibleModalUploadImage] =
    useState<boolean>(false);
  const [visibleModalBlingSend, setVisibleModalBlingSend] =
    useState<boolean>(false);
  const [visibleModalDocs, setVisibleModalDocs] = useState<boolean>(false);

  const location = useLocation();
  const windowSize = useWindowSize();
  const responsiveWindow = useResponsiveSizeTable(windowSize);

  const orderStatus = {
    0: "Em aberto 1/4",
    1: "Confirmado 2/4",
    2: "Em trânsito 3/4",
    3: "Finalizado 4/4",
    4: "Cancelado",
    5: "Em estoque",
    6: "Estornado",
  };

  const getStatusOrder = (order: OrderModel) => {
    if (order.to_delivery && order.status < 4) {
      if (order.status === 0) {
        return `Aguardando confirmação`;
      } else {
        return `Retirar às ${order.time_to_get}`;
      }
    } else {
      return orderStatus[order.status];
    }
  };

  const getFile = async (
    orderId: number | undefined,
    type: "pdf" | "csv"
  ): Promise<void> => {
    Modal.confirm({
      title: "Atenção",
      content: `Deseja realmente baixar o ${
        type === "pdf" ? "PDF" : "CSV"
      } deste pedido?`,
      className: "modalConfirm",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        try {
          setLoadingFile(true);
          const { data: pdf } = await api.get(`/orders/${orderId}/${type}`, {
            responseType: "blob",
          });
          const url = window.URL.createObjectURL(
            new Blob([pdf], {
              type: type === "pdf" ? "application/pdf" : "text/csv",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.target = type === "pdf" ? "_blank" : "_self";
          if (type === "csv") {
            link.download = `pedido_${orderId}.csv`;
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch {
          //@ts-ignore
          const _description = error.message;
          notification.error({
            message: "Falha ao gerar arquivo.",
            description: _description,
            duration: 5,
          });
        } finally {
          setLoadingFile(false);
        }
      },
    });
  };

  const getActivedProductsBling = async (order_id) => {
    try {
      setLoadingBling(true);

      const {
        data: { data },
      } = await api.get(`order/products/bling/${order_id}`);
      setOrderBlingStatus(data);
    } catch (error) {
      notification.error({
        message:
          "Não foi possível buscar as informações do pedido para importação no Bling",
        duration: 5,
      });
    } finally {
      setLoadingBling(false);
    }
  };

  const firstOrder = async (
    selectOrder: number | undefined,
    firstOrder: boolean
  ) => {
    Modal.confirm({
      title: "Marcar primeiro pedido",
      content: (
        <span>
          Deseja continuar e <b>{firstOrder ? "marcar" : "desmarcar"} </b>
          este pedido como <b>Primeiro Pedido</b>?
        </span>
      ),
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        setShouldSearch(true);
        try {
          const payload = {
            id: orderSelect?.id,
            status: orderSelect?.status,
            time_to_get: orderSelect?.time_to_get,
            to_delivery: orderSelect?.to_delivery,
            total: orderSelect?.total,
            first_order: firstOrder,
          };

          await api.put(`/orders/${selectOrder}`, payload);

          notification.success({
            message: (
              <span>
                Primeiro pedido <b>{firstOrder ? "marcado" : "desmarcado"} </b>
                com sucesso
              </span>
            ),
            duration: 5,
          });
          setShouldSearch(true);
        } catch (e) {
          //@ts-ignore
          const _description = e?.response?.data?.error?.message;
          notification.warning({
            message: (
              <span>
                Não foi possível <b>{firstOrder ? "marcar" : "desmarcar"}</b> o
                pedido como <b>Primeiro Pedido</b>
              </span>
            ),
            description: _description,
            duration: 5,
          });
        } finally {
          setShouldSearch(false);
        }
      },
    });
  };

  const handleDelete = async (selectOrder: number | undefined) => {
    Modal.confirm({
      title: "Remoção de pedido",
      content: "Deseja continuar e confirmar a remoção deste pedido?",
      className: "modalDelete",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        setShouldSearch(true);
        try {
          await api.delete(`/orders/${selectOrder}`);

          notification.success({
            message: "Pedido removido com sucesso",
            duration: 5,
          });
          setShouldSearch(true);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;
          notification.error({
            message: "Erro ao remover pedido",
            description: _description,
            duration: 5,
          });
        } finally {
          setShouldSearch(false);
        }
      },
    });
  };

  const blingHandler = async (order_id?: number) => {
    try {
      setLoadingFile(true);
      await api.delete(`/orders/remove-to-bling/${order_id}`);
      notification.success({
        message: "Pedido estornado do bling com sucesso",
        duration: 5,
      });
      setShouldSearch(true);
    } catch (error) {
      //@ts-ignore
      if (error.response.data.error.status === 404) {
        notification.error({
          message:
            "Esse pedido foi excluido no Bling, verifique o número do pedido no Bling.",
          duration: 5,
        });

        //@ts-ignore
      } else if (error.response.data.error.code === 3) {
        notification.error({
          //@ts-ignore
          message: error.response.data.error.msg,
          duration: 5,
        });
      } else if (error) {
        //@ts-ignore
        const errors = Array.isArray(error.response.data.error)
          ? //@ts-ignore
            error.response.data.error.join("---")
          : //@ts-ignore
          error.response.data.error.message
          ? //@ts-ignore
            [error.response.data.error.message]
          : //@ts-ignore
            [error.response.data.error.error.fields[0].msg];

        Modal.error({
          title: "Erro ao estornar o pedido do bling",
          //@ts-ignore
          content: errors,
          okText: "Sim",
          okType: "primary",
        });
      }
    } finally {
      setLoadingFile(false);
      setShouldSearch(false);
    }
  };

  const validateOrderType = () => {
    if (orderSelect?.status === OrderStatus.CANCELADO) {
      return false;
    }
    if (orderSelect?.status === OrderStatus.CONFIRMADO) {
      if (
        verifyPermission("orders.change_status") ||
        verifyPermission("orders.cancel_franchisor")
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (
      verifyPermission("orders.cancel_opened_order") ||
      verifyPermission("orders.acept") ||
      verifyPermission("orders.change_status") ||
      verifyPermission("orders.cancel_franchisor")
    ) {
      return true;
    }
    return false;
  };

  const onTotvsClick = async (action: "add" | "remove", order_id?: number) => {
    Modal.confirm({
      title: "Atenção",
      content: `Deseja continuar e ${
        action === "add" ? "adicionar" : "remover"
      } este pedido ${action === "add" ? "à" : "da"} Totvs?`,
      className: "modalConfirm",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        try {
          await api.patch(
            `/order/totvs/${order_id}/${action === "add" ? "send" : "remove"}`
          );

          notification.success({
            message: `Pedido ${
              action === "add" ? "enviado" : "estornado"
            } com sucesso`,
            duration: 5,
          });

          setShouldSearch(true);
        } catch (error) {
          const error_message =
            //@ts-ignore
            error.response?.data?.error?.message ||
            //@ts-ignore
            error.response?.error?.message ||
            //@ts-ignore
            error.response?.data?.message ||
            //@ts-ignore
            error.response?.message ||
            //@ts-ignore
            error.message ||
            "Um erro interno aconteceu";

          notification.error({
            message: `Falha ao ${
              action === "add" ? "enviar" : "remover"
            } pedido ${action === "add" ? "à" : "da"} Totvs.`,
            description: error_message,
            duration: 5,
          });
        }
      },
    });
  };

  const onRoutechClick = async (
    action: "add" | "remove",
    order_id?: number
  ) => {
    Modal.confirm({
      title: "Atenção",
      content: `Deseja continuar e ${
        action === "add" ? "adicionar" : "remover"
      } este pedido ${action === "add" ? "à" : "da"} Routech?`,
      className: "modalConfirm",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      width: 500,
      async onOk() {
        try {
          if (action === "add") {
            await api.post(`/order/routech/send`, { order_ids: [order_id] });
          } else {
            await api.delete(`/order/routech/${order_id}/remove`);
          }

          notification.success({
            message: `Pedido ${
              action === "add" ? "enviado" : "estornado"
            } com sucesso`,
            duration: 5,
          });

          setShouldSearch(true);
        } catch (error) {
          const error_message =
            //@ts-ignore
            error.response?.data?.error?.message ||
            //@ts-ignore
            error.response?.error?.message ||
            //@ts-ignore
            error.response?.data?.message ||
            //@ts-ignore
            error.response?.message ||
            //@ts-ignore
            error.message ||
            "Um erro interno aconteceu";

          notification.error({
            message: `Falha ao ${
              action === "add" ? "enviar" : "remover"
            } pedido ${action === "add" ? "à" : "da"} Routech.`,
            description: error_message,
            duration: 5,
          });
        }
      },
    });
  };

  const menu = (
    <Menu>
      {validateOrderType() && (
        <Menu.SubMenu title="Alterar status">
          {verifyPermission("orders.acept") &&
            orderSelect?.status === OrderStatus.EM_ABERTO && (
              <Menu.Item
                onClick={() => {
                  setVisibleModalStatusChange(true);
                  setStatus(1);
                }}
              >
                Aceitar Pedido
              </Menu.Item>
            )}

          {verifyPermission("orders.change_status") &&
            !orderSelect?.to_delivery &&
            orderSelect?.status === OrderStatus.CONFIRMADO && (
              <Menu.Item
                onClick={() => {
                  setVisibleModalStatusChange(true);
                  setStatus(2);
                }}
              >
                Enviar para transporte
              </Menu.Item>
            )}

          {(orderSelect?.status === OrderStatus.EM_TRANSPORTE ||
            (orderSelect?.to_delivery &&
              orderSelect?.status < OrderStatus.FINALIZADO)) && (
            <Menu.Item
              onClick={() => {
                setVisibleModalStatusChange(true);
                setStatus(3);
              }}
            >
              Finalizar Pedido
            </Menu.Item>
          )}

          {(orderSelect?.status === OrderStatus.FINALIZADO ||
            orderSelect?.status === OrderStatus.ESTORNADO) && (
            <Menu.Item
              onClick={() => {
                setVisibleModalStatusChange(true);
                setStatus(5);
              }}
            >
              Importar para estoque
            </Menu.Item>
          )}

          {orderSelect?.status === OrderStatus.EM_ESTOQUE && (
            <Menu.Item
              onClick={() => {
                setVisibleModalStatusChange(true);
                setStatus(6);
              }}
            >
              Estornar do estoque
            </Menu.Item>
          )}

          {verifyPermission("orders.cancel_franchisor")
            ? (orderSelect?.status === OrderStatus.EM_ABERTO ||
                orderSelect?.status === OrderStatus.CONFIRMADO ||
                orderSelect?.status === OrderStatus.EM_TRANSPORTE) && (
                <Menu.Item
                  onClick={() => {
                    setVisibleModalStatusChange(true);
                    setStatus(4);
                  }}
                >
                  Cancelar Pedido
                </Menu.Item>
              )
            : verifyPermission("orders.cancel_opened_order") &&
              orderSelect?.status === OrderStatus.EM_ABERTO && (
                <Menu.Item
                  onClick={() => {
                    setVisibleModalStatusChange(true);
                    setStatus(4);
                  }}
                >
                  Cancelar Pedido
                </Menu.Item>
              )}
        </Menu.SubMenu>
      )}
      {verifyPermission("orders.rating") &&
        orderSelect?.status &&
        orderSelect.status >= OrderStatus.FINALIZADO &&
        orderSelect?.status !== OrderStatus.CANCELADO && (
          <Menu.Item onClick={() => setVisibleModalRating(true)}>
            Avaliação do pedido
          </Menu.Item>
        )}

      {verifyPermission("orders.edit") &&
        orderSelect?.status &&
        orderSelect.status >= OrderStatus.FINALIZADO &&
        orderSelect?.status !== OrderStatus.CANCELADO && (
          <Menu.Item
            onClick={() => {
              setOrderToEdit(orderSelect);
              orderSelect?.type_of_load === 2
                ? setVisibleModalHomologOrder &&
                  setVisibleModalHomologOrder(true)
                : setVisibleEdit(true);
            }}
          >
            Editar pedido
          </Menu.Item>
        )}

      <Menu.Item onClick={() => setVisibleModalMovement(true)}>
        Acompanhar Entrega
      </Menu.Item>
      {verifyPermission("orders.payment_historic") &&
        orderSelect?.order_payments?.length !== 0 && (
          <Menu.Item onClick={() => setVisibleModalAuditPayment(true)}>
            Movimentação de pagamento
          </Menu.Item>
        )}

      {verifyPermission("orders.add_payment") &&
        orderSelect?.status &&
        orderSelect.status >= OrderStatus.FINALIZADO &&
        orderSelect?.status !== OrderStatus.CANCELADO &&
        (orderSelect.total_nf !== null && orderSelect.total_nf !== undefined
          ? orderSelect?.payment_value !== +orderSelect.total_nf
          : orderSelect?.payment_value !== +orderSelect.total) && (
          <Menu.Item onClick={() => setVisibleModalPayment(true)}>
            Confirmar pagamento
          </Menu.Item>
        )}

      {verifyPermission("orders.first_order") && (
        <Menu.Item
          onClick={() => {
            firstOrder(
              orderSelect?.id,
              orderSelect?.first_order ? false : true
            );
          }}
        >
          {orderSelect?.first_order
            ? "Desmarcar primeiro pedido"
            : "Marcar como primeiro pedido"}
        </Menu.Item>
      )}
      {verifyPermission("orders.pdf_and_csv") && (
        <Menu.Item
          onClick={() => {
            getFile(orderSelect?.id, "pdf");
          }}
        >
          Gerar PDF
        </Menu.Item>
      )}
      {verifyPermission("orders.pdf_and_csv") && (
        <Menu.Item
          onClick={() => {
            getFile(orderSelect?.id, "csv");
          }}
        >
          Gerar CSV
        </Menu.Item>
      )}

      {orderSelect?.uploadOrders &&
        orderSelect?.uploadOrders?.length < 10 &&
        verifyPermission("orders.add_image") && (
          <Menu.Item onClick={() => setVisibleModalUploadImage(true)}>
            Adicionar Imagem
          </Menu.Item>
        )}
      {verifyPermission("orders.historic") && (
        <Menu.Item
          onClick={() => {
            setVisibleModalHistoryOrderItens(true);
          }}
        >
          Histórico do Pedido
        </Menu.Item>
      )}
      {orderSelect?.store.country !== "paraguai" &&
        orderSelect?.status !== 4 &&
        orderSelect?.type_of_load !== 2 &&
        verifyPermission("orders.send_to_bling") && (
          <Menu.Item
            onClick={() => {
              setVisibleModalBlingSend(true);
              getActivedProductsBling(orderSelect?.id);
            }}
          >
            Importar para o Bling
          </Menu.Item>
        )}
      {orderSelect?.store.country !== "paraguai" &&
        orderSelect?.orderItems.find(
          (item) => item?.sale_order_bling_id !== null
        ) &&
        verifyPermission("orders.send_to_bling") && (
          <Menu.Item onClick={async () => await blingHandler(orderSelect?.id)}>
            Estornar do Bling
          </Menu.Item>
        )}

      {verifyPermission("orders.send_to_totvs") &&
        !orderSelect?.totvs_id &&
        orderSelect?.type_of_load !== 2 && (
          <Menu.Item
            onClick={async () => await onTotvsClick("add", orderSelect?.id)}
          >
            Importar para Totvs
          </Menu.Item>
        )}

      {verifyPermission("orders.send_to_totvs") &&
        orderSelect?.totvs_id &&
        orderSelect?.type_of_load !== 2 && (
          <Menu.Item
            onClick={async () => await onTotvsClick("remove", orderSelect?.id)}
          >
            Estornar da Totvs
          </Menu.Item>
        )}
      {verifyPermission("orders.send_to_routech") &&
        orderSelect?.totvs_id &&
        !orderSelect?.routech_id &&
        orderSelect?.type_of_load !== 2 && (
          <Menu.Item
            onClick={async () => await onRoutechClick("add", orderSelect?.id)}
          >
            Importar para Routech
          </Menu.Item>
        )}

      {verifyPermission("orders.send_to_routech") &&
        orderSelect?.routech_id &&
        orderSelect?.type_of_load !== 2 && (
          <Menu.Item
            onClick={async () =>
              await onRoutechClick("remove", orderSelect?.id)
            }
          >
            Estornar da Routech
          </Menu.Item>
        )}

      {verifyPermission("orders.delete_order") && (
        <Menu.Item
          onClick={() => {
            handleDelete(orderSelect?.id);
          }}
          style={{ color: "red" }}
        >
          Excluir
        </Menu.Item>
      )}
    </Menu>
  );

  const getStatus = (deliveryStatus, id) => {
    const status = verifyStatusRoute(deliveryStatus, id);

    if (
      status === DeliveryRouteStatus.ATRIBUIDA ||
      status === DeliveryRouteStatus.ROTEIRIZADA ||
      status === DeliveryRouteStatus.CRIADA
    ) {
      return "Entrega em Preparação";
    }
    if (status === DeliveryRouteStatus.EM_ROTA) {
      return "Em Transporte";
    }
    if (status === DeliveryRouteStatus.EM_ROTA_ATE_VOCE) {
      const deliveryDate = deliveryStatus[0].deliveryRouteItem[0].expected_date;

      return deliveryDate
        ? `Em rota até você. Data prevista de chegada: 
      ${moment(deliveryDate, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY")}`
        : `Em rota até você`;
    }
    if (status === DeliveryRouteStatus.FINALIZADA) {
      return "Entrega Realizada com Sucesso";
    }
    if (status === DeliveryRouteStatus.EM_PAUSA) {
      return "Transporte em Pausa";
    }
    return "Entrega Indisponível";
  };

  const getRowClassNameTotal = (record) => {
    if (+record?.total === +record?.payment_value?.toFixed(2)) {
      return "totalPay";
    } else if (
      record.status === 3 &&
      +record.payment_value < +record.total &&
      record.order_payments?.length === 0
    ) {
      return "emptyPay";
    } else if (
      +record.payment_value < +record.total &&
      +record.payment_value !== 0 &&
      record.order_payments?.length > 0
    ) {
      return "partialPay";
    } else {
      return "default";
    }
  };


  const columns = [
    {
      title: "Nº",
      dataIndex: "vhsys",
      key: "vhsys",
      width: windowSize.width > 575 ? 90 : 40,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: windowSize.width > 575 ? 130 : 100,
      render: (text, record) => (
        <StatusDiv>
          <Tooltip title={getStatusOrder(record)}>
            <StatusInfo order_status={text} to_delivery={record.to_delivery}>
              {getStatusOrder(record)}
            </StatusInfo>
          </Tooltip>
          {record.first_order && (
            <Tooltip title="Primeiro pedido">
              <FirstOrderIcon />
            </Tooltip>
          )}
        </StatusDiv>
      ),
    },
    {
      title: "Data/Hora",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["xl"] as any,
      width: 110,
      render: (text) => (
        <Tooltip
          title={moment(text, "DD-MM-YYYY HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        >
          <span>
            {moment(text, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}
          </span>
        </Tooltip>
      ),
    },
    ...(!countryPY
      ? [
          {
            title: "Valor Nota Fiscal",
            dataIndex: "total_nf",
            key: "total_nf",
            responsive: ["md"] as any,
            width: countryPY ? 110 : 110,
            render: (text) => (
              <Tooltip
                title={
                  text !== undefined && text !== null
                    ? `R$ ${currencyFormater(+text)}`
                    : " - "
                }
              >
                <span>
                  {text !== undefined && text !== null ? (
                    `R$ ${currencyFormater(+text)}`
                  ) : (
                    <Centralizer>-</Centralizer>
                  )}
                </span>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      title: "Tipo de Carga",
      dataIndex: "type_of_load",
      key: "type_of_load",
      responsive: ["lg"] as any,
      width: 120,
      render: (text) => (
        <Tooltip
          title={
            text === 0
              ? "Gelada"
              : text === 1
              ? "Seca"
              : text === 2
              ? "Fornecedor Homologado"
              : "-"
          }
        >
          <span>
            {text === 0
              ? "Gelada"
              : text === 1
              ? "Seca"
              : text === 2
              ? "Fornecedor Homologado"
              : "-"}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Loja",
      dataIndex: "Loja",
      key: "Loja",
      responsive: ["md"] as any,
      width: 150,
      render: (_, record) => (
        <Tooltip
          title={
            record.store ? record.store?.company_name : "Loja não identificada"
          }
        >
          <span style={{ textTransform: "capitalize" }}>
            {record.store
              ? record.store?.company_name
              : "Loja não identificada"}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: windowSize.width > 575 ? 120 : 70,
      render: (text) => (
        <Tooltip
          title={`${countryPY ? `US$` : `R$`} ${currencyFormater(+text)}`}
        >
          <span>
            {countryPY ? `US$` : `R$`} {currencyFormater(+text)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <Center>Situação</Center>,
      dataIndex: "rating",
      key: "rating",
      width: windowSize.width > 575 ? 110 : 60,
      render: (_, record) => (
        <Center>
          {verifyPermission("orders.send_to_totvs") &&
            record?.type_of_load !== 2 && (
              <>
                {record?.totvs_id ? (
                  <Tooltip title={"Importado para a Totvs"}>
                    <TotvsIcon src={TotsvImport} />
                  </Tooltip>
                ) : (
                  <Tooltip title={"Não importado para a Totvs"}>
                    <TotvsIcon src={TotsvNotImport} />
                  </Tooltip>
                )}
              </>
            )}
          {verifyPermission("orders.send_to_routech") &&
            record?.type_of_load !== 2 && (
              <>
                {record?.routech_id ? (
                  <Tooltip title={"Importado para a Routech"}>
                    <RoutechIcon src={RoutechImport} />
                  </Tooltip>
                ) : (
                  <Tooltip title={"Não importado para a Routech"}>
                    <RoutechIcon src={RoutechNotImport} />
                  </Tooltip>
                )}
              </>
            )}
          {verifyPermission("orders.send_to_bling") &&
            record?.type_of_load !== 2 && (
              <>
                {record?.status_integration_bling === 2 &&
                record?.type_of_load !== 2 ? (
                  <Tooltip title={"Todos importados para o Bling"}>
                    <BlingIcon src={BlingImport} />
                  </Tooltip>
                ) : record?.status_integration_bling === 1 &&
                  record?.type_of_load !== 2 ? (
                  <Tooltip title={"Parcialmente importados para o Bling"}>
                    <BlingIcon src={BlingPartialImport} />
                  </Tooltip>
                ) : (
                  <Tooltip title={"Não foi importado para o Bling"}>
                    <BlingIcon src={BlingReverse} />
                  </Tooltip>
                )}
              </>
            )}

          <Tooltip title={"Avaliação"}>
            {record?.rating_assistant ? (
              !record?.obs_rating_assistant ? (
                <StarIcon />
              ) : (
                <StarIconComment />
              )
            ) : (
              <StarEmpty />
            )}
          </Tooltip>
          {verifyStatusRoute(record?.deliveryRoutes, record?.id) !==
            DeliveryRouteStatus.CANCELADA && (
            <Tooltip title={`${getStatus(record?.deliveryRoutes, record?.id)}`}>
              {verifyStatusRoute(record?.deliveryRoutes, record?.id) !==
              DeliveryRouteStatus.FINALIZADA ? (
                <DeliveryIcon
                  status={verifyStatusRoute(record?.deliveryRoutes, record?.id)}
                />
              ) : (
                <PackageIcon />
              )}
            </Tooltip>
          )}
          <Tooltip title={"Ver Documentos"}>
            <DocumentsIcon
              onClick={() => {
                setOrderSelect(record);
                setVisibleModalDocs(true);
              }}
            />
          </Tooltip>
        </Center>
      ),
    },
    {
      title: <Center>Info</Center>,
      dataIndex: "Info",
      key: "Info",
      width: windowSize.width > 575 ? 70 : 30,
      render: (_, record) => (
        <Center>
          <InfoIcon
            onClick={() => {
              setVisibleModalInfo(true);
              setOrderSelect(record);
            }}
          />

          {record?.uploadOrders?.length !== 0 && (
            <ImageIcon
              onClick={() => {
                setVisibleModalImage(true);
                setOrderSelect(record);
              }}
            />
          )}
        </Center>
      ),
    },
    ...(location.pathname !== "/send_bling_py"
      ? [
          {
            title: <Center>Ação</Center>,
            dataIndex: "Ação",
            key: "Ação",
            width: windowSize.width > 575 ? 70 : 25,
            render: (_: any, record: any) => (
              <Center>
                {!isStoreActive && (
                  <Dropdown
                    overlay={menu}
                    placement="bottomCenter"
                    arrow
                    trigger={["click"]}
                  >
                    <MoreIcon onClick={() => setOrderSelect(record)} />
                  </Dropdown>
                )}
              </Center>
            ),
          },
        ]
      : []),
  ];

  const rowSelection = {
    selectedRowKeys: selectedOrderIds,
    onChange: (selectedRowKeys: React.Key[]) => {
      onSelectedChange && onSelectedChange(selectedRowKeys as number[]);
    },
  };

  return (
    <>
      {loadingFile ? (
        <Centralizer>
          <Spinner />
        </Centralizer>
      ) : (
        <Table
          rowSelection={showCheckboxes ? rowSelection : undefined}
          columns={columns}
          dataSource={orders.map((order) => ({
            ...order,
            key: order?.id,
            orderItems: order?.orderItems?.sort((current, next) =>
              current.products?.name &&
              next.products?.name &&
              current.products?.name > next.products?.name
                ? 1
                : current.products?.name &&
                  next.products?.name &&
                  next.products?.name > current.products?.name
                ? -1
                : 0
            ),
            payment_value: order?.order_payments?.reduce(
              (total, item) =>
                item?.deleted_at ? total : total + +item?.payment_value,
              0
            ),
          }))}
          scroll={{ y: responsiveWindow - (windowSize.width > 700 ? 0 : 100) }}
          pagination={false}
        />
      )}
      <ModalStatusChange
        setVisible={setVisibleModalStatusChange}
        visible={visibleModalStatusChange}
        orderSelect={orderSelect}
        status={status}
        setStatus={setStatus}
        setShouldSearch={setShouldSearch}
        setVisibleEdit={setVisibleEdit}
        setVisibleModalHomologOrder={setVisibleModalHomologOrder}
        setOrderToEdit={setOrderToEdit}
      />
      <ModalAuditPayment
        setVisible={setVisibleModalAuditPayment}
        visible={visibleModalAuditPayment}
        order={orderSelect}
        setShouldSearch={setShouldSearch}
      />
      <ModalHistoryOrderItens
        setVisible={setVisibleModalHistoryOrderItens}
        visible={visibleModalHistoryOrderItens}
        order={orderSelect}
      />
      <ModalInfoOrder
        setVisible={setVisibleModalInfo}
        visible={visibleModalInfo}
        order={orderSelect}
        categories={categories}
        countryPY={countryPY}
        providers={providers}
        productPercentage={productPercentage}
      />
      <ModalRatingOrder
        setVisible={setVisibleModalRating}
        visible={visibleModalRating}
        order={orderSelect}
        setShouldSearch={setShouldSearch}
      />
      <ModalPayment
        setVisible={setVisibleModalPayment}
        visible={visibleModalPayment}
        order={orderSelect}
        setShouldSearch={setShouldSearch}
        countryPY={countryPY}
      />
      <ModalImageOrder
        setVisible={setVisibleModalImage}
        visible={visibleModalImage}
        order={orderSelect}
        setShouldSearch={setShouldSearch}
      />
      <ModalUploadImageOrder
        setVisible={setVisibleModalUploadImage}
        visible={visibleModalUploadImage}
        order={orderSelect}
        setShouldSearch={setShouldSearch}
      />
      <ModalBlingSend
        loadingBling={loadingBling}
        setVisible={setVisibleModalBlingSend}
        visible={visibleModalBlingSend}
        order={orderBlingStatus}
        setShouldSearch={setShouldSearch}
      />
      <ModalMovement
        setVisible={setVisibleModalMovement}
        visible={visibleModalMovement}
        order={orderSelect}
      />
      <ModalOrderDocuments
        visible={visibleModalDocs}
        onClose={() => setVisibleModalDocs(false)}
        order={orderSelect}
      />
    </>
  );
};

export default OrdersList;
