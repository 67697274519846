import React, { Dispatch, SetStateAction } from "react";
import moment from "moment";
import { Container, Dropdown, Menu, Table, ThreeDotsIcon } from "./styles";

import { InvoiceRequest } from "../models/invoiceRequest";
import { ColumnsType } from "antd/lib/table";

type IProps = {
  invoicesRequest: InvoiceRequest[];
  fetchingInvoices: boolean;
  setSelectedInvoiceRequest: Dispatch<SetStateAction<InvoiceRequest | null>>;
  setOpenSendInvoicesRequest: Dispatch<SetStateAction<boolean>>;
};

const ListInvoices: React.FC<IProps> = ({
  invoicesRequest,
  fetchingInvoices,
  setSelectedInvoiceRequest,
  setOpenSendInvoicesRequest,
}) => {
  const menu = (row) => {
    return (
      <>
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => {
              setSelectedInvoiceRequest(row);
              setOpenSendInvoicesRequest(true);
            }}
          >
            <span>Abrir</span>
          </Menu.Item>
          {/* <Menu.Item key="1">
            <span>Baixar CSV</span>
          </Menu.Item>
          <Menu.Item key="2">
            <span>Excluir</span>
          </Menu.Item> */}
        </Menu>
      </>
    );
  };

  const columns: ColumnsType = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Data Referência",
      dataIndex: "reference_date",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Cobranças Totais",
      dataIndex: "total_invoices",
      align: "center",
    },
    {
      title: "Cobranças Enviadas",
      dataIndex: "invoices_sended",
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      align: "center",
      render: (value) => (
        <span>{value ? value.replace(".", ",") : "0,00"}</span>
      ),
    },
    {
      title: "Total enviado",
      dataIndex: "total_sended",
      align: "center",
      render: (value) => (
        <span>{value ? value.replace(".", ",") : "0,00"}</span>
      ),
    },
    {
      title: "Criado Por",
      dataIndex: "created_by_name",
      align: "center",
    },
    {
      title: "Data",
      dataIndex: "created_at",
      align: "center",
      render: (value) => (
        <span>
          {moment(value).subtract(3, "hours").format("DD/MM/YYYY HH:mm:ss")}
        </span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      align: "center",
      render: (_, row) => (
        <Dropdown overlay={menu(row)}>
          <ThreeDotsIcon />
        </Dropdown>
      ),
    },
  ];

  return (
    <Container>
      <Table
        loading={fetchingInvoices}
        scroll={{ y: 500 }}
        pagination={{
          showSizeChanger: false,
          pageSize: 50,
          total: invoicesRequest.length,
        }}
        columns={columns as any}
        dataSource={invoicesRequest.map((invoice) => ({
          ...invoice,
          key: invoice.id,
        }))}
      />
    </Container>
  );
};

export default ListInvoices;
