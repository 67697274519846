import styled from "styled-components";
import {
  Modal,
  Row,
  Col as ColAnt,
  Table as TableAnt,
  Tabs as TabsAnt,
} from "antd";

import { InfoCircle } from "../../../styles/Icons";

export const Container = styled(Modal)`
  .order {
    text-transform: capitalize;
    color: var(--orange-350);
  }
  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
    .time {
      font-size: 1.1rem;
    }
  }
  .ant-modal-header {
    padding: 1rem 2rem;
  }
  .ant-modal-body {
    padding: 0.5rem 2rem;
    max-height: 100%;
    overflow-y: auto;
  }
  .itemsTable {
    table tr:nth-child(2n) td {
      background-color: var(--white-100);
    }
  }
  .categoryTable {
    margin-top: 1.5rem;
    .ant-table-thead > tr > th {
      padding: 0.2rem;
    }

    .ant-table-tbody > tr > td {
      padding: 0.2rem;
    }
  }
`;

export const OtherValuesOrder = styled(Row)`
  margin-bottom: 1rem;

  .notComment {
    color: var(--gray-170);
  }
`;

export const Col = styled(ColAnt)`
  span,
  label {
    font-weight: 500;
  }

  span {
    color: var(--gray-650);
  }

  label {
    color: var(--orange-350);
  }
`;

export const Table = styled(TableAnt)`
  border: 1px solid var(--gray-25);

  .ant-table-thead > tr > th {
    background: white;

    padding: 0.6rem;

    color: var(--gray-530);

    font-weight: 500;
    @media only screen and (max-width: 500px) {
      font-size: 0.7rem;
      padding: 0.8rem 0.2rem;
    }
  }

  .ant-table-tbody > tr > td {
    background: white;

    padding: 0.6rem;

    color: var(--gray-530);

    font-weight: 400;
    font-size: 1rem;

    text-transform: capitalize;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    white-space: nowrap;

    @media only screen and (max-width: 500px) {
      font-size: 0.6rem;
      padding: 0.8rem 0.2rem;
    }
  }
`;

export const Freight = styled.span`
  color: #686f82;

  padding: 0.5rem;
`;

export const Tabs = styled(TabsAnt)`
  .ant-tabs-nav {
    margin: 0 0 0 0;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 10px 30px;
    background-color: var(--gray-250);
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--orange-350);
    :hover {
      background: var(--orange-350);
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    text-decoration: none;
  }
  .ant-tabs-tab {
    :hover {
      color: white;
      background-color: var(--gray-300);
    }
  }

  @media only screen and (max-width: 575px) {
    .ant-tabs-nav .ant-tabs-tab {
      padding: 5px 15px;
    }
  }
`;

export const IconInfo = styled(InfoCircle)`
  color: var(--rainbows-outer-rim);
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
`;
