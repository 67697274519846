import React, { SetStateAction, Dispatch, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../services/api";

import { verifyPermission } from "../../services/auth";
import { currencyFormater } from "../../services/currencyFormater";

import { CategoryModel } from "../../models/CategoryModel";
import { Product as ProductModel } from "../../models/Product";

import ModalEditProduct from "../../components/ModalNewEditProduct";

import { useWindowSize } from "../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../hooks/useResponsiveSizeTable";

import { Tooltip, notification, Modal } from "antd";

import {
  Container,
  LabelName,
  Actions,
  Button,
  StoreIcon,
  OrderIcon,
  EditIcon,
  RemoveIcon,
  RestoreIcon,
  Table,
  HeaderCenter,
  SalesIcon,
} from "./styles";
import { Provider } from "../../models/Provider";

const { confirm } = Modal;

interface IProps extends RouteComponentProps {
  products: ProductModel[];
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setProviderSearch: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  categories?: CategoryModel[];
  providers?: Provider[];
}

const ProductList: React.FC<IProps> = ({
  products,
  setShouldSearch,
  loading,
  setLoading,
  categories,
  providers,
  setProviderSearch
}) => {
  const [visibleEditProduct, setVisibleEditProduct] = useState(false);
  const [productEdit, setProductEdit] = useState<
    ProductModel | null | undefined
  >();

  const responsiveWindow = useWindowSize();

  const handlerPerfilClick = (product: ProductModel) => {
    setProductEdit(product);
    setVisibleEditProduct(true);
  };

  const handleUpdateProduct = async (
    id: number | undefined,
    updatedProduct: ProductModel,
    action: string
  ) => {
    const msgEdit =
      action === "store"
        ? "Deseja habilitar o produto para Produtos do Gestor?"
        : action === "orders"
        ? "Deseja habilitar o produto para Pedidos?"
        : "Deseja habilitar o produto para Compras?";

    confirm({
      title: "Atualizar Produto",
      content: msgEdit,
      okText: "Sim",
      okType: "default",
      cancelText: "Não",
      className: "modalEdit",
      width: 600,
      async onOk() {
        setLoading(true);
        try {
          await api.put(`/products/${id}`, updatedProduct);
          notification.success({
            message: "Produto atualizado com sucesso!",
            duration: 5,
          });
        } catch {
          notification.error({
            message: "Erro ao atualizar produto!",
            duration: 5,
          });
        }
        setShouldSearch(true);
        setLoading(false);
      },
    });
  };

  const columns = [
    {
      title: "Código",
      dataIndex: "cod_product",
      key: "cod_product",
      width: "10%",
      sorter: (cod_ant, cod_prox) => cod_ant.cod_product - cod_prox.cod_product,
      render: (text) => (
        <Tooltip title={text}>
          <LabelName>{text}</LabelName>
        </Tooltip>
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: `${responsiveWindow.width > 500 ? "25%" : "15%"}`,
      render: (text) => (
        <Tooltip title={text}>
          <LabelName>{text}</LabelName>
        </Tooltip>
      ),
    },
    {
      title: "Categoria",
      dataIndex: "category?.name",
      key: "category?.name",
      width: "15%",
      responsive: ["md"] as any,
      render: (text, record) => (
        <Tooltip title={`${record.category?.name}`}>
          <LabelName>{record.category?.name}</LabelName>
        </Tooltip>
      ),
    },
    {
      title: "Valor Venda",
      dataIndex: "price_sell",
      key: "price_sell",
      width: `${responsiveWindow.width > 500 ? "15%" : "10%"}`,
      sorter: (price_Ant, price_prox) =>
        price_Ant.price_sell - price_prox.price_sell,
      render: (text) => (
        <Tooltip title={`R$ ${currencyFormater(+text || 0)}`}>
          <LabelName>R$ {currencyFormater(+text || 0)}</LabelName>
        </Tooltip>
      ),
    },
    {
      title: <HeaderCenter>Ações</HeaderCenter>,
      width: "20%",
      render: (text, record) => (
        <Actions>
          <Tooltip placement="top" title="Loja" color="var(--orange-350)">
            <Button
              onClick={() =>
                handleUpdateProduct(
                  record.id,
                  {
                    permission_store: !record.permission_store,
                  },
                  "store"
                )
              }
              icon={<StoreIcon checked={record.permission_store} />}
            />
          </Tooltip>

          <Tooltip placement="top" title="Pedidos" color="var(--green-400)">
            <Button
              onClick={() =>
                handleUpdateProduct(
                  record.id,
                  {
                    permission_order: !record.permission_order,
                    permission_purchase: record.permission_purchase,
                  },
                  "orders"
                )
              }
              icon={<OrderIcon checked={record.permission_order} />}
            />
          </Tooltip>
          <Tooltip placement="top" title="Compras" color="blue">
            <Button
              onClick={() =>
                handleUpdateProduct(
                  record.id,
                  {
                    permission_purchase: !record.permission_purchase,
                  },
                  "orderBuy"
                )
              }
              icon={<SalesIcon checked={record.permission_purchase} />}
            />
          </Tooltip>

          {verifyPermission("product.edit") && (
            <Tooltip placement="top" title="Editar" color="blue">
              <Button
                onClick={() => handlerPerfilClick(record)}
                icon={<EditIcon />}
              />
            </Tooltip>
          )}
        </Actions>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Table
          loading={loading}
          columns={columns}
          scroll={{ y: useResponsiveSizeTable(responsiveWindow) }}
          dataSource={products.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
        />
      </Container>

      <ModalEditProduct
        visible={visibleEditProduct}
        setVisible={setVisibleEditProduct}
        setShouldSearch={setShouldSearch}
        productEdit={productEdit}
        categories={categories}
        providers={providers}
        setProviderSearch={setProviderSearch}
      ></ModalEditProduct>
    </>
  );
};

export default withRouter(ProductList);
