import React, { Dispatch, SetStateAction } from "react";

import { useWindowSize } from "../../../hooks/useWindowSize";

import { Provider } from "../../../models/Provider";
import { CategoryModel } from "../../../models/CategoryModel";
import { Order as OrderModel } from "../../../models/Order/Order";

import { cleanSpecialChars } from "../../../services/cleanSpecialChars";
import { currencyFormater } from "../../../services/currencyFormater";

import {
  Container,
  Table,
  OtherValuesOrder,
  Col,
  Freight,
  Tabs,
  IconInfo,
} from "./styles";
import { Tooltip } from "antd";

const { TabPane } = Tabs;

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  categories: CategoryModel[];
  countryPY?: boolean;
  providers?: Provider[];
  productPercentage?: number | null;
}

type TotalCategory = {
  totalQuantity: number;
  totalWeight: number;
  totalValue: number;
};

const ModalInfoOrder: React.FC<IProps> = ({
  visible,
  setVisible,
  order,
  categories,
  countryPY,
  providers,
  productPercentage
}) => {
  const windowSize = useWindowSize();

  const calculateServicePercentage = () => {
    if (!order) return null;
    const productPercent = order.product_percentage !== null ? Number(order.product_percentage) : productPercentage;
    return productPercent ? 1 - productPercent : null;
  };

  const servicePercentage = visible && order ? calculateServicePercentage() ?? 0 : 0;

  const handleTotalCategory = (category: string[]): TotalCategory => {
    const categoriesName = category.map((category) =>
      cleanSpecialChars(category).toLowerCase()
    );

    const resultTotalQuatity = order?.orderItems.reduce(
      (total, orderItem) =>
        categoriesName.some(
          (category) =>
            category ===
            cleanSpecialChars(orderItem.products.category?.name.toLowerCase())
        )
          ? total + +orderItem.quantity
          : total + 0,
      0
    );

    const resultTotalWeight = order?.orderItems.reduce(
      (total, orderItem) =>
        categoriesName.some(
          (category) =>
            category ===
            cleanSpecialChars(orderItem.products.category?.name.toLowerCase())
        )
          ? total + +orderItem.quantity * +(orderItem.products.weight || 0)
          : total + 0,
      0
    );

    const resultTotalValue = order?.orderItems.reduce(
      (total, orderItem) =>
        categoriesName.some(
          (category) =>
            category ===
            cleanSpecialChars(orderItem.products.category?.name.toLowerCase())
        )
          ? total + +orderItem.quantity * +(orderItem.price_unit || 0)
          : total + 0,
      0
    );

    return {
      totalQuantity: resultTotalQuatity || 0,
      totalWeight: resultTotalWeight || 0,
      totalValue: resultTotalValue || 0,
    };
  };

  const columnTableItems = [
    {
      title: "Nome",
      dataIndex: "products.name",
      key: "products.name",
      render: (_, record) => {
        const productName = countryPY
          ? record?.products?.name_py || "Produto sem nome"
          : record?.products?.name || "Produto sem nome";

        return (
          <Tooltip
            title={
              productName !== "Produto sem nome"
                ? productName
                : "Sem informações do nome do produto"
            }
          >
            <span>{productName}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Valor Unit.",
      dataIndex: "price_unit",
      key: "price_unit",
      render: (text) => (
        <span>
          {countryPY ? `US$` : `R$`} {currencyFormater(+text)}
        </span>
      ),
    },
    ...(order?.store.country !== "paraguai" && order?.type_of_load !== 2
      ? [
        {
          title: "Valor Prod.",
          render: (_, record) => (
            <span>
              {countryPY ? 'US$' : 'R$'}{" "}
              {currencyFormater(
                +record.price_unit *
                (order?.product_percentage !== null
                  ? Number(order?.product_percentage)
                  : productPercentage || 0)
              )}
            </span>
          ),
        },
        {
          title: "Valor Serv.",
          render: (_, record) => (
            <span>
              {countryPY ? `US$` : `R$`}
              {currencyFormater(+record.price_unit * (servicePercentage || 0))}
            </span>
          ),
        },
      ]
      : []),
    {
      title: "Qtd",
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
      render: (text) => <span>{text}</span>,
    },
    ...(order?.store.country === "paraguai" || order?.type_of_load === 2
      ? [
        {
          title: "Total",
          render: (_, record) => (
            <span>
              {" "}
              {countryPY ? `US$` : `R$`}{" "}
              {currencyFormater(+record.price_unit * (+record.quantity || 0))}
            </span>
          ),
        },
      ]
      : []),
    ...(order?.store.country !== "paraguai" && order?.type_of_load !== 2
      ? [
        {
          title: "Total Prod.",
          render: (_, record) => (
            <span>
              R${" "}
              {currencyFormater(
                +record.price_unit *
                (+record.quantity || 0) *
                (order?.product_percentage !== null
                  ? Number(order?.product_percentage)
                  : productPercentage || 0)
              )}
            </span>
          ),
        },
        {
          title: "Total Serv.",
          render: (_, record) => (
            <span>
              {" "}
              R${" "}
              {currencyFormater(
                +record.price_unit * (+record.quantity || 0) * (servicePercentage || 0)
              )}
            </span>
          ),
        },
      ]
      : []),
    ...(order?.type_of_load !== 2
      ? [
          {
            title: "Carga",
            sorter: (a, b) =>
              a.products?.type_cargo?.length - b.products?.type_cargo?.length,
            render: (_, record) => (
              <div>
                <Freight>
                  {record?.products?.type_cargo === 0 ? (
                    "Gelada"
                  ) : record?.products?.type_cargo === 1 ? (
                    "Seca"
                  ) : (
                    <Tooltip
                      title={"O produto não tem um tipo de carga cadastrado"}
                    >
                      <IconInfo />
                    </Tooltip>
                  )}
                </Freight>
              </div>
            ),
          },
        ]
      : []),
  ];

  const columnTableTotalCategory = [
    {
      title: "Categoria",
      dataindex: "name",
      key: "name",
      render: (_, record) => <span>{record.name}</span>,
    },
    {
      title: "Quantidade Total",
      dataindex: "result.totalQuantity",
      key: "result.totalQuantity",
      render: (_, record) => <span>{record.result.totalQuantity}</span>,
    },
    {
      title: "Peso Total",
      dataindex: "result.totalWeight",
      key: "result.totalWeight",
      render: (_, record) => (
        <span>{currencyFormater(record.result.totalWeight)} KG</span>
      ),
    },
    ...(order?.store.country === "paraguai"
      ? [
        {
          title: "Valor Total",
          dataindex: "result.totalValue",
          key: "result.totalValue",
          render: (_, record) => (
            <span>
              {countryPY ? `US$` : `R$`}{" "}
              {currencyFormater(+record.result.totalValue)}
            </span>
          ),
        },
      ]
      : []),
    ...(order?.store.country !== "paraguai" && order?.type_of_load !== 2
      ? [
        {
          title: "Valor Produtos",
          render: (_, record) => (
            <span>
              R${" "}
              {currencyFormater(
                record.result.totalValue *
                (order?.product_percentage !== null
                  ? Number(order?.product_percentage)
                  : productPercentage || 0)
              )}
            </span>
          ),
        },
        {
          title: "Valor Serviços",
          render: (_, record) => (
            <span>
              R$ {currencyFormater(record.result.totalValue * (servicePercentage || 0))}
            </span>
          ),
        },
      ]
      : []),
  ];

  const categoriesDataSource = [
    {
      key: 1,
      name: "Açaí/Sorvetes",
      result: visible && handleTotalCategory(["acai", "sorvetes"]),
    },
    {
      key: 1,
      name: "Potes p/ Revenda",
      result: visible && handleTotalCategory(["potes p/ revenda"]),
    },
    {
      key: 2,
      name: "Outros",
      result:
        visible &&
        handleTotalCategory(
          categories
            .map((category) => category.name)
            .filter(
              (category_name) =>
                category_name !== "acai" &&
                category_name !== "sorvetes" &&
                category_name !== "potes p/ revenda"
            )
        ),
    },
    {
      key: 3,
      name: "Geral",
      result:
        visible &&
        handleTotalCategory(categories.map((category) => category.name)),
    },
  ];

  const providerDataSource = [
    {
      key: 1,
      name: "Geral",
      result:
        visible &&
        handleTotalCategory(categories.map((category) => category.name)),
    },
  ];

  const categoriesGelada = [
    {
      key: 1,
      name: "Açaí",
      result: visible && handleTotalCategory(["acai"]),
    },
    {
      key: 2,
      name: "Sorvetes",
      result: visible && handleTotalCategory(["sorvetes"]),
    },
    {
      key: 3,
      name: "Congelados",
      result: visible && handleTotalCategory(["congelados"]),
    },
    {
      key: 4,
      name: "Potes p/ Revenda",
      result: visible && handleTotalCategory(["potes p/ revenda"]),
    },
  ];

  const orderNFProductTotal = currencyFormater(
    handleTotalCategory(categories.map((category) => category.name))
      ?.totalValue *
    (order?.product_percentage || 0) +
    +(order?.value_freight || 0) +
    +(order?.additional_value || 0) -
    +((order?.discount || 0) + (order?.discount_product || 0))
  );

  return (
    <Container
      title={
        <>
          <span>
            Dados do pedido: <span className="order">{order?.vhsys}</span>
          </span>
          {order?.delivery_time && (
            <span className="time">
              Previsão de entrega: {order?.delivery_time}
            </span>
          )}
          {order?.type_of_load === 2 && providers && providers?.length > 0 && (
            <span>
              Fornecedor:{" "}
              <span className="order">
                {
                  providers?.find(
                    (_provider) =>
                      _provider.id ===
                      order?.orderItems[0]?.products?.certified_provider_id
                  )?.company_name
                }
              </span>
            </span>
          )}
        </>
      }
      visible={visible}
      centered
      onCancel={() => setVisible(false)}
      width={1000}
      footer={null}
    >
      <OtherValuesOrder gutter={4}>
        {!countryPY && (
          <>
            {order?.type_of_load !== 2 ? (
              <Col sm={8}>
                <span>Valor Total Produtos NF: </span>
                <label>
                  R${" "}
                  {order?.franchise_tax
                    ? order?.total_nf || orderNFProductTotal || 0
                    : order?.total}
                </label>
              </Col>
            ) : (
              <Col sm={8}>
                <span>Valor Total: </span>
                <label>R$ {order?.total}</label>
              </Col>
            )}
            {order?.type_of_load !== 2 && (
              <>
                <Col sm={6}>
                  <span>Valor IPI: </span>
                  <label>R$ {order?.value_ipi || 0}</label>
                </Col>
                <Col sm={5}>
                  <span>Valor ICMS ST: </span>
                  <label>R$ {order?.value_icms_st || 0}</label>
                </Col>
              </>
            )}
          </>
        )}
        {order?.type_of_load !== 2 && (
          <>
            <Col sm={5}>
              <span>Valor Adicional: </span>
              <label>
                {" "}
                {countryPY ? `US$` : `R$`} {order?.additional_value || 0}
              </label>
            </Col>
            {!countryPY && (
              <Col sm={8}>
                <span>Valor Demais Impostos: </span>
                <label>R$ {order?.value_additional_taxes || 0}</label>
              </Col>
            )}
            <Col sm={6}>
              <span>Valor de Frete: </span>
              <label>
                {" "}
                {countryPY ? `US$` : `R$`} {order?.value_freight || 0}
              </label>
            </Col>
            {!countryPY && (
              <Col sm={5}>
                <span>Juros: </span>
                <label>R$ {order?.value_interest || 0}</label>
              </Col>
            )}
            <Col sm={5}>
              <span>Desconto: </span>
              <label>
                {" "}
                {countryPY ? `US$` : `R$`} {order?.discount || 0}
              </label>
            </Col>
            <Col sm={8}>
              <span>Desconto de Produtos: </span>
              <label>
                {" "}
                {countryPY ? `US$` : `R$`}{" "}
                {currencyFormater(+(order?.discount_product || 0))}
              </label>
            </Col>
          </>
        )}
      </OtherValuesOrder>

      <OtherValuesOrder>
        <Col sm={24}>
          <span>Comentário: </span>
          <span>
            {order?.comment?.length === 0 || order?.comment === null ? (
              <span className="notComment">Não há comentários.</span>
            ) : (
              order?.comment
            )}
          </span>
        </Col>
      </OtherValuesOrder>

      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Geral" key="1">
          <Table
            columns={columnTableItems}
            dataSource={order?.orderItems}
            rowKey={(entity: any) => entity.id}
            className="itemsTable"
            scroll={{ y: windowSize.height > 800 ? 300 : 180 }}
            pagination={false}
          />

          <Table
            columns={columnTableTotalCategory}
            dataSource={
              order?.type_of_load !== 2
                ? categoriesDataSource
                : providerDataSource
            }
            rowKey={(entity: any) => entity.id}
            className="categoryTable"
            pagination={false}
          />
        </TabPane>
        {order?.type_of_load !== 2 && (
          <>
            <TabPane tab={`Carga Gelada`} key="2">
              <Table
                columns={columnTableItems}
                dataSource={order?.orderItems?.filter(
                  (item) => item?.products?.type_cargo === 0
                )}
                rowKey={(entity: any) => entity.id}
                className="itemsTable"
                scroll={{ y: windowSize.height > 800 ? 300 : 180 }}
                pagination={false}
              />

              <Table
                columns={columnTableTotalCategory}
                dataSource={categoriesGelada}
                rowKey={(entity: any) => entity.id}
                className="categoryTable"
                pagination={false}
              />
            </TabPane>

            <TabPane tab={`Carga Seca`} key="3">
              <Table
                columns={columnTableItems}
                dataSource={order?.orderItems?.filter(
                  (item) => item?.products?.type_cargo === 1
                )}
                rowKey={(entity: any) => entity.id}
                className="itemsTable"
                scroll={{ y: windowSize.height > 800 ? 300 : 180 }}
                pagination={false}
              />

              <Table
                columns={columnTableTotalCategory}
                dataSource={categoriesDataSource.filter(
                  (item) => item.key === 2
                )}
                rowKey={(entity: any) => entity.id}
                className="categoryTable"
                pagination={false}
              />
            </TabPane>
          </>
        )}
      </Tabs>
    </Container>
  );
};

export default ModalInfoOrder;
