import styled from "styled-components";

import { ThreeDots } from "../../../styles/Icons";

import {
  Table as TableAnt,
  Dropdown as DropdownAnt,
  Menu as MenuAnt,
} from "antd";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Table = styled(TableAnt)`
  width: 100%;
`;

export const Dropdown = styled(DropdownAnt)``;

export const Menu = styled(MenuAnt)``;

export const ThreeDotsIcon = styled(ThreeDots)`
  width: 20px;
`;
