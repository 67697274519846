import styled from "styled-components";

import { Button as ButtonAnt } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 10%;
`;

export const ListContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Title = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const Button = styled(ButtonAnt)`
  height: 3.2rem;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-350);
  color: var(--white);

  :hover,
  :focus {
    background-color: var(--orange-350);
    color: var(--white);
    opacity: 70%;
  }
`;
