import React, { useEffect, useState } from "react";
import { Moment } from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";
import { useStoreData } from "../../../hooks/useStoreData";
import { useHistory } from "react-router-dom";

import api from "../../../services/api";
import { currencyFormater } from "../../../services/currencyFormater";
import { getTokenInfo, verifyPermission } from "../../../services/auth";
import { exportCSVFile } from "../../../services/exportCSVFile";

import { IntegrationBling } from "../../../models/enums/IntegrationBling";

import { CategoryModel } from "../../../models/CategoryModel";
import { Order as OrderModel } from "../../../models/Order/Order";
import { Page } from "../../../models/Page";

import Pagination from "../../../components/Pagination";
import GetStore from "../../../components/GetStore";
import Spinner from "../../../components/Spinner";
import StoreSelectionPage from "../../../components/StoreSelectionPage";
import DisabledFlag from "../../../components/DisabledFlag";

import Centralizer from "../../../containers/Centralizer";
import PageContainer from "../../../containers/PageContainer";

import OrderList from "../OrdersList";
import ModalCreateEditOrder from "../ModalCreateEditOrder";

import OrderSvg from "../../../assets/svg/order_vector_py.svg";
import orderIcecream from "../../../assets/svg/orderIcecream.svg";
import orderFruit from "../../../assets/svg/orderFruit.svg";
import orderNuts from "../../../assets/svg/orderNuts.svg";
import orderCups from "../../../assets/svg/orderCups.svg";
import Paraguai from "../../../assets/images/paraguai.webp";

import {
  Button,
  DatePicker,
  Empty,
  Modal,
  Tooltip,
  message,
  notification,
} from "antd";

import {
  Container,
  Content,
  Header,
  ActionFilterContainer,
  ActionButton,
  AddIcon,
  FilterIcon,
  List,
  Dropdown,
  Menu,
  Col,
  ColAction,
  Checkbox,
  FilterText,
  SearchIcon,
  DownloadIcon,
  TotalContainer,
  ValuePayment,
  InputOrders,
  BlockIcon,
  UnlockIcon,
  WhatsappIcon,
  WhatsappText,
  ContentModal,
  ButtonCancel,
  ButtonCard,
  ModalDivButtons,
  ModalTypeLoad,
  Icons,
  ParaguaiBling,
  ParaguaiButton,
  ParaguaiContainerIcon,
} from "./styles";

import ModalBlockStore from "../ModalBlockStore";
import { WarningIcon } from "../ModalBlockStore/styles";
import apiAuth from "../../../services/apiAuth";
import moment from "moment";
const { RangePicker } = DatePicker;

interface Filter {
  paid: boolean;
  vhsys: boolean;
  rated: boolean;
}

enum ReasonType {
  "Pedidos mais de 15 dias em atraso" = 0,
  "Boletos de renegociação vencidos" = 1,
  "O fechamento semanal está acima do permitido" = 2,
}

const ListOrderPY: React.FC = () => {
  const [store, setStore] = useState<number | null | undefined>(null);
  const [paraguayCompanyIds, setParaguayCompanyIds] = useState<number[]>([]);
  const [allStores, setAllStores] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const history = useHistory();
  const [orderToEdit, setOrderToEdit] = useState<OrderModel>();

  const [shouldSearch, setShouldSearch] = useState<boolean>(false);
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [loadingOrders, setLoadinOrders] = useState<boolean>(false);

  const [loadingReset, setLoadingReset] = useState<boolean>(false);

  const [minOrder, setMinOrder] = useState<number | undefined>();
  const [totalPayment, setTotalPayment] = useState<number>(0);

  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);

  const [status, setStatus] = useState<number[]>([]);
  const [vhsysCod, setVhsysCod] = useState<string>("");

  const [typeOfLoad, setTypeOfLoad] = useState<number | undefined>();

  const { isStoreActive, isBlockedStore, setShouldSearchStore } =
    useStoreData(store);
  const [visibleTypeLoadModal, setVisibleTypeLoadModal] =
    useState<boolean>(false);
  const [visibleModalCreateEditOrder, setVisibleModalCreateEditOrder] =
    useState(false);
  const [visibleModalBlockStore, setvisibleModalBlockStore] = useState(false);

  const [modalBlockFranchisee, setModalBlockFranchisee] = useState(false);
  const [selectedReason, setSelectedReason] = useState<number | undefined>(
    undefined
  );

  const [blingIntegrationFilter, setBlingIntegrationFilter] = useState({
    [IntegrationBling.NAO_IMPORTADO]: false,
    [IntegrationBling.PARCIALMENTE_IMPORTADO]: false,
    [IntegrationBling.IMPORTADO]: false,
  });

  const handleBlingIntegrationChange = (blingValue) => {
    setBlingIntegrationFilter((prevState) => ({
      [IntegrationBling.NAO_IMPORTADO]:
        blingValue === IntegrationBling.NAO_IMPORTADO
          ? !prevState[IntegrationBling.NAO_IMPORTADO]
          : false,
      [IntegrationBling.PARCIALMENTE_IMPORTADO]:
        blingValue === IntegrationBling.PARCIALMENTE_IMPORTADO
          ? !prevState[IntegrationBling.PARCIALMENTE_IMPORTADO]
          : false,
      [IntegrationBling.IMPORTADO]:
        blingValue === IntegrationBling.IMPORTADO
          ? !prevState[IntegrationBling.IMPORTADO]
          : false,
    }));
  };

  const blingReset = {
    [IntegrationBling.NAO_IMPORTADO]: false,
    [IntegrationBling.PARCIALMENTE_IMPORTADO]: false,
    [IntegrationBling.IMPORTADO]: false,
  };

  const [statusMenu, setStatusMenu] = useState<
    {
      id: number;
      description: string;
      check: boolean;
    }[]
  >([
    { id: 0, description: "Em Aberto", check: false },
    { id: 1, description: "Confirmado", check: false },
    { id: 2, description: "Em Transporte", check: false },
    { id: 3, description: "Finalizado", check: false },
    { id: 4, description: "Cancelado", check: false },
    { id: 5, description: "Em Estoque", check: false },
    { id: 6, description: "Estornado", check: false },
  ]);

  const [filter, setFilter] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<Filter>({
    paid: false,
    vhsys: false,
    rated: false,
  });

  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 30,
    totalElements: 0,
  });

  useEffect(() => {
    const fetchCategories = async () => {
      setLoadinOrders(true);
      try {
        const {
          data: { content },
        } = await api.get(`/product_categories/products/orders`);

        setCategories(content);
      } catch (error) {
        //@ts-ignore
        const _description = error.data;

        notification.error({
          message: "Erro ao buscar categorias",
          description: _description,
          duration: 5,
        });
      } finally {
        setLoadinOrders(false);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await getTokenInfo();
      setUser(currentUser);
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      statusMenu.forEach(
        (statusCheck) => statusCheck.check && status.push(statusCheck.id)
      );
      setLoadingStore(true);
      try {
        let URL = allStores
          ? `/orders?stores_ids=${paraguayCompanyIds.join(",")}`
          : `/orders?stores_ids=${store}`;

        URL += `&page=${paginate.page}&size=${paginate.size}`;

        if (selectedDate && selectedDate[0] && selectedDate[1]) {
          let initialDate = selectedDate[0].format("DD/MM/YYYY");
          let finalDate = selectedDate[1].format("DD/MM/YYYY");

          URL += `&data_inicial=${initialDate}&data_final=${finalDate}`;
        }
        if (filter) {
          if (filterOptions.paid) {
            URL += `&orderByPayDate=${filterOptions.paid}`;
          }
          if (filterOptions.rated) {
            URL += `&withRating=${filterOptions.rated}`;
          }

          if (status?.length > 0) {
            URL += `&status=${status?.toString()}`;
            setStatus([]);
          }
          if (filterOptions.vhsys && vhsysCod) {
            URL += `&vhsys=${vhsysCod}`;
          }

          Object.entries(blingIntegrationFilter).forEach(([key, value]) => {
            if (value) {
              URL += `&status_integration_bling=${key}`;
            }
          });
        }

        const { data: pagination } = await api.get(URL);
        const { totalElements, content } = pagination;

        setShouldSearch(false);
        setPaginate((oldValues) => ({ ...oldValues, totalElements }));

        setOrders(content[0] ? content : []);
      } catch (error) {
        //@ts-ignore
        const _description = error.data;

        notification.error({
          message: "Erro ao buscar pedidos",
          description: _description,
          duration: 5,
        });
      } finally {
        setLoadingReset(false);
        setLoadingStore(false);
        setShouldSearch(false);
      }
    };

    if ((store || allStores) && shouldSearch) {
      fetchOrders();
    }
  }, [store, shouldSearch, allStores, filter, paginate, selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      const url = allStores
        ? "/order/total_payment"
        : `/order/total_payment?store_id=${store}`;
      const {
        data: { result },
      } = await api.get(url);
      setTotalPayment(result);
      setShouldSearch(false);
    };

    if (shouldSearch) {
      fetchData();
    }
  }, [store, allStores, shouldSearch]);

  const changeDefaulterStatus = async () => {
    try {
      setvisibleModalBlockStore(false);

      const newStatus = !isBlockedStore;
      const reason = newStatus
        ? selectedReason !== undefined
          ? selectedReason
          : null
        : null;

      await apiAuth.put(`/company/${store}`, {
        defaulter_status: newStatus,
        defaulter_reason: reason,
      });
      notification.success({
        message: newStatus
          ? "Loja bloqueada com sucesso!"
          : "Loja desbloqueada com sucesso!",
        duration: 3,
      });
      setShouldSearchStore(true);
      setShouldSearch(true);
    } catch (error) {
      //@ts-ignore
      const message = error?.response?.data?.message;
      notification.error({
        message: message || "Erro ao fazer a atualização",
        duration: 5,
      });
    }
  };

  const handleCSV = () => {
    const headers = {
      vhsys: "VHSYS",
      name: "Nome",
      quantity: "QUANTIDADE",
      total: "TOTAL",
    };

    const orderPayload = orders.map((order) => {
      const itemPayload = order.orderItems.map((orderItem) => ({
        vhsys: "---",
        name: orderItem.products.name,
        quantity: +orderItem.quantity,
        total: +orderItem.quantity * +(orderItem.products.price_sell || 0),
      }));

      const response = [
        {
          vhsys: order.vhsys,
          name: order.store.company_name,
          quantity: itemPayload.reduce(
            (total, item) => total + +item.quantity,
            0
          ),
          total: order.total,
        },
        ...itemPayload,
      ];

      return response;
    });
    const response = [];
    orderPayload.forEach((payload) => {
      //@ts-ignore
      payload.forEach((_payload) => response.push(_payload));
    });

    exportCSVFile(headers, response, "pedidos");
  };

  const validateStore = (value: string) => {
    if (!store && allStores) {
      return notification.warning({
        message: "Selecione uma loja.",
        description:
          "É necessário selecionar alguma loja para realizar um novo pedido.",
        duration: 5,
      });
    } else if (value === "newOrder") {
      setVisibleTypeLoadModal(true);
    } else {
      handleCSV();
    }
  };

  const filterReset = () => {
    setLoadingReset(true);
    setAllStores(false);
    setPaginate({ page: 1, size: 30, totalElements: 0 });
    setFilterOptions({
      paid: false,
      vhsys: false,
      rated: false,
    });

    let _status: { id: number; description: string; check: boolean }[] =
      statusMenu;

    statusMenu.forEach(
      (oldValues, index) =>
        (_status[index] = {
          ...oldValues,
          check: false,
        })
    );
    setStatusMenu(_status);
    setVhsysCod("");
    setShouldSearch(true);
    setBlingIntegrationFilter(blingReset);
  };

  const changeCheck = (ID: number) => {
    const index = statusMenu.findIndex((entity) => entity.id === ID);

    const _statusChange = statusMenu;
    _statusChange[index].check = !_statusChange[index].check;

    setStatusMenu(_statusChange);
  };

  const handleChangeTypeOfLoad = () => {
    const today = moment().day();
    if (
      today >= 2 &&
      today <= 4 &&
      !verifyPermission("orders.do_cold_orders")
    ) {
      return Modal.confirm({
        title: "Pedidos de Gelados Indisponíveis",
        cancelButtonProps: { hidden: true },
        content:
          "Os pedidos de gelados estão desabilitados de Terça a Quinta-feira. Para agendamento prévio, entre em contato com a logística.",
      });
    } else {
      setTypeOfLoad(0);
      setVisibleModalCreateEditOrder(true);
      setVisibleTypeLoadModal(false);
    }
  };

  const filterMenu = loadingReset ? (
    <Centralizer>
      <Spinner />
    </Centralizer>
  ) : (
    <Menu>
      <Menu.Item>
        <FilterText
          onClick={() => {
            filterReset();
          }}
        >
          Limpar filtros
        </FilterText>
      </Menu.Item>
      {verifyPermission("orders.all_stores") && (
        <Menu.Item
          onClick={() => {
            setShouldSearch(true);
            setPaginate((oldValues) => ({ ...oldValues, page: 1 }));
            setAllStores(!allStores);
          }}
        >
          <FilterText>Todas as lojas</FilterText>
          <Checkbox checked={allStores} />
        </Menu.Item>
      )}
      {verifyPermission("orders.send_to_bling") && (
        <Menu.Item
          onClick={() =>
            handleBlingIntegrationChange(IntegrationBling.NAO_IMPORTADO)
          }
        >
          <FilterText>Não Importado Bling</FilterText>
          <Checkbox
            checked={blingIntegrationFilter[IntegrationBling.NAO_IMPORTADO]}
          />
        </Menu.Item>
      )}

      {verifyPermission("orders.send_to_bling") && (
        <Menu.Item
          onClick={() =>
            handleBlingIntegrationChange(
              IntegrationBling.PARCIALMENTE_IMPORTADO
            )
          }
        >
          <FilterText>Parcialmente Importado Bling</FilterText>
          <Checkbox
            checked={
              blingIntegrationFilter[IntegrationBling.PARCIALMENTE_IMPORTADO]
            }
          />
        </Menu.Item>
      )}

      {verifyPermission("orders.send_to_bling") && (
        <Menu.Item
          onClick={() =>
            handleBlingIntegrationChange(IntegrationBling.IMPORTADO)
          }
        >
          <FilterText>Importado Bling</FilterText>
          <Checkbox
            checked={blingIntegrationFilter[IntegrationBling.IMPORTADO]}
          />
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          setFilterOptions({ ...filterOptions, paid: !filterOptions.paid });
        }}
      >
        <FilterText>Pedidos Pagos</FilterText>
        <Checkbox checked={filterOptions.paid} />
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setPaginate((oldValues) => ({ ...oldValues, page: 1 }));
          setFilterOptions({
            ...filterOptions,
            vhsys: !filterOptions.vhsys,
          });
        }}
      >
        <FilterText>Código vhsys</FilterText>
        <Checkbox checked={filterOptions.vhsys} />
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setPaginate((oldValues) => ({ ...oldValues, page: 1 }));
          setFilterOptions({
            ...filterOptions,
            rated: !filterOptions.rated,
          });
        }}
      >
        <FilterText>Avaliado</FilterText>
        <Checkbox checked={filterOptions.rated} />
      </Menu.Item>
      {statusMenu.map((_status) => (
        <Menu.Item
          key={_status.id}
          onChange={() => {
            setPaginate((oldValues) => ({ ...oldValues, page: 1 }));
            changeCheck(_status.id);
          }}
          defaultValue={_status.id}
        >
          <Checkbox
            className="statusCheck"
            value={_status.check}
            checked={_status.check}
          ></Checkbox>
          <FilterText>{_status.description}</FilterText>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleDateChange = (dates) => {
    message.info("Clique no ícone de lupa para iniciar a pesquisa.", 1.5);
    setSelectedDate(dates);
  };

  const handleBlockStoreClick = async () => {
    Modal.confirm({
      title: isBlockedStore ? "Desbloquear Loja" : "Bloquear Loja",
      content: isBlockedStore
        ? "Deseja desbloquear esta loja?"
        : "Deseja bloquear esta loja?",
      centered: true,
      okText: isBlockedStore ? "Desbloquear" : "Bloquear",
      cancelText: "Cancelar",
      async onOk() {
        await changeDefaulterStatus();
      },
      onCancel() {},
    });
  };

  return (
    <PageContainer route="Pedidos Paraguai">
      {loadingOrders ? (
        <Centralizer>
          <Spinner />
        </Centralizer>
      ) : (
        <Container>
          <DisabledFlag isStoreActive={isStoreActive} />

          {!store && !allStores ? (
            <StoreSelectionPage
              title="Para realizar um novo pedido"
              Img={OrderSvg}
              store={store}
              setStore={setStore}
              setShouldSearch={setShouldSearch}
              permissionCheck={"orders.all_stores"}
              checkBoxVisible={true}
              checked={allStores}
              setAll={setAllStores}
              textCheckBox={"Pedido de todas as lojas"}
              setMinOrder={setMinOrder}
              filterCountryPY={true}
              setParaguayCompanyIds={setParaguayCompanyIds}
            />
          ) : (
            <>
              {loadingStore ? (
                <Centralizer>
                  <Spinner />
                </Centralizer>
              ) : (
                <>
                  <Header>
                    <TotalContainer>
                      <h2>Lista de pedidos</h2>
                      
                    </TotalContainer>
                    <ActionFilterContainer gutter={8}>
                      <Col
                        className="store-select-content"
                        sm={filterOptions.vhsys ? 5 : 9}
                        xs={filterOptions.vhsys ? 12 : 24}
                      >
                        <GetStore
                          defaultValue={store}
                          handleChange={(id) => {
                            setStore(id);
                            setAllStores(false);
                            setShouldSearch(true);
                          }}
                          setMinOrder={setMinOrder}
                          filterCountryPY={true}
                        />
                      </Col>

                      <Col xl={4} sm={6} xs={24}>
                        <RangePicker
                          placeholder={["Data inicial", "Data final"]}
                          locale={locale}
                          format="DD/MM/YYYY"
                          onChange={handleDateChange}
                        />
                      </Col>

                      {/* <Col xl={3} sm={3} xs={6}>
                  <InputOrders
                    placeholder="N° da NF"
                    type="number"
                    onChange={({ target: { value } }) => {
                      setVhsysCod(value?.toString());
                    }}
                  ></InputOrders>
                </Col> */}
                      {filterOptions.vhsys && (
                        <Col xl={3} sm={3} xs={8}>
                          <InputOrders
                            placeholder="Código Vhysy"
                            type="number"
                            onChange={({ target: { value } }) => {
                              setVhsysCod(value?.toString());
                            }}
                          ></InputOrders>
                        </Col>
                      )}

                      <ColAction sm={1}>
                        <Tooltip title={"Pesquisar"}>
                          <ActionButton
                            onClick={() => {
                              setFilter(true);
                              setShouldSearch(true);
                            }}
                          >
                            <SearchIcon />
                          </ActionButton>
                        </Tooltip>
                      </ColAction>
                      {verifyPermission("orders.send_to_bling") && (
                        <ColAction sm={1}>
                          <Tooltip title={"Consolidar pedidos Totvs"}>
                            <span>
                              <ParaguaiButton
                                onClick={() => history.push("/send_bling_py")}
                              >
                                <ParaguaiContainerIcon>
                                  <ParaguaiBling src={Paraguai} />
                                </ParaguaiContainerIcon>
                              </ParaguaiButton>
                            </span>
                          </Tooltip>
                        </ColAction>
                      )}
                      {!isStoreActive && (
                        <>
                          <ColAction sm={1}>
                            <Dropdown
                              onVisibleChange={(state) => {
                                setOpenFilter(state);
                              }}
                              visible={openFilter}
                              overlay={filterMenu}
                              trigger={["click"]}
                              placement="bottomCenter"
                            >
                              <Tooltip title={"Filtro"}>
                                <ActionButton>
                                  <FilterIcon />
                                </ActionButton>
                              </Tooltip>
                            </Dropdown>
                          </ColAction>

                          <ColAction sm={1}>
                            <Tooltip title={"Download CSV"}>
                              <ActionButton
                                onClick={() => validateStore("downloadCsv")}
                              >
                                <DownloadIcon />
                              </ActionButton>
                            </Tooltip>
                          </ColAction>

                          {!allStores && verifyPermission("orders.block") && (
                            <ColAction sm={1}>
                              <Tooltip
                                title={
                                  !isBlockedStore
                                    ? "Bloquear loja"
                                    : "Desbloquear loja"
                                }
                              >
                                <ActionButton
                                  onClick={() => {
                                    isBlockedStore
                                      ? handleBlockStoreClick()
                                      : setvisibleModalBlockStore(true);
                                  }}
                                >
                                  {isBlockedStore ? (
                                    <UnlockIcon />
                                  ) : (
                                    <BlockIcon />
                                  )}
                                </ActionButton>
                              </Tooltip>
                            </ColAction>
                          )}

                          <ColAction sm={1}>
                            {verifyPermission("orders.add") &&
                              (isBlockedStore ? (
                                <>
                                  <Tooltip title={"Franqueado Bloqueado"}>
                                    <ActionButton
                                      onClick={() =>
                                        setModalBlockFranchisee(true)
                                      }
                                    >
                                      <WarningIcon size={25} />
                                    </ActionButton>
                                  </Tooltip>
                                  <Modal
                                    centered
                                    title="Atenção"
                                    visible={modalBlockFranchisee}
                                    onCancel={() =>
                                      setModalBlockFranchisee(false)
                                    }
                                    footer={[
                                      <Button
                                        key="cancel"
                                        onClick={() =>
                                          setModalBlockFranchisee(false)
                                        }
                                      >
                                        Fechar
                                      </Button>,
                                    ]}
                                  >
                                    <p>
                                      A loja selecionada está bloqueada de fazer
                                      pedidos novos! <br />O motivo do bloqueio
                                      é{" "}
                                      {selectedReason !== undefined
                                        ? ReasonType[selectedReason]
                                        : ""}
                                      .<br />
                                      <br />
                                      Para desbloquear a sua loja, contate o
                                      nosso Financeiro:
                                    </p>

                                    <ContentModal>
                                      <WhatsappIcon />
                                      <WhatsappText
                                        href="https://api.whatsapp.com/send?phone=5543991857703&text=Ol%C3%A1%21%20Preciso%20de%20mais%20informa%C3%A7%C3%B5es%20acerca%20do%20bloqueio%20da%20minha%20loja."
                                        target="_blank"
                                        onClick={() =>
                                          setModalBlockFranchisee(false)
                                        }
                                      >
                                        Contato Financeiro
                                      </WhatsappText>
                                    </ContentModal>
                                  </Modal>
                                </>
                              ) : (
                                <Tooltip title={"Novo Pedido"}>
                                  <ActionButton
                                    onClick={() => {
                                      validateStore("newOrder");
                                    }}
                                  >
                                    <AddIcon />
                                  </ActionButton>
                                </Tooltip>
                              ))}
                          </ColAction>
                        </>
                      )}
                    </ActionFilterContainer>
                  </Header>
                  <>
                    {!orders?.length ? (
                      <Centralizer>
                        <Empty description="Nenhum pedido registrado para essa loja" />
                      </Centralizer>
                    ) : (
                      <Content>
                        <List key={"list-order"}>
                          <OrderList
                            orders={orders}
                            setShouldSearch={setShouldSearch}
                            categories={categories}
                            setVisibleEdit={setVisibleModalCreateEditOrder}
                            setOrderToEdit={setOrderToEdit}
                            isStoreActive={isStoreActive}
                            countryPY={true}
                          />
                        </List>
                        {paginate.totalElements > 0 && (
                          <Pagination
                            setStateSearch={setShouldSearch}
                            setPaginate={setPaginate}
                            defaultPageSize={30}
                            showSizeChanger={false}
                            current={paginate.page}
                            totalElements={paginate.totalElements}
                          />
                        )}
                      </Content>
                    )}
                  </>
                </>
              )}
            </>
          )}
        </Container>
      )}

      <ModalTypeLoad
        title={"Novo Pedido"}
        visible={visibleTypeLoadModal}
        closable={true}
        onCancel={() => {
          setVisibleTypeLoadModal(false);
        }}
        width={900}
        footer={[
          <ButtonCancel
            onClick={() => {
              setVisibleTypeLoadModal(false);
            }}
          >
            Cancelar
          </ButtonCancel>,
        ]}
      >
        <>
          <h3>Selecione o tipo de pedido:</h3>
          <ModalDivButtons>
            <ButtonCard onClick={() => handleChangeTypeOfLoad()} typeStyle={1}>
              <>
                <div className="icons">
                  <Icons src={orderIcecream} />
                  <Icons src={orderFruit} />
                </div>
                <div className="title">Pedidos de Gelados</div>
                <div className="subTitle">Açaís, Sorvetes e Congelados</div>
                <div className="divText">
                  <ul>
                    <li>
                      Pedidos devem ser realizados observando sua quinzena;
                    </li>
                    <li>
                      Disponível apenas entre <b>Sábado 8h até Domingo 23h</b>;
                    </li>
                    <li>
                      Pedidos extras podem ser realizados{" "}
                      <b>às sextas e às segundas com agendamento prévio</b> com
                      o Departamento de Logística.
                    </li>
                  </ul>
                </div>
              </>
            </ButtonCard>
            <ButtonCard
              onClick={() => {
                setTypeOfLoad(1);
                setVisibleModalCreateEditOrder(true);
                setVisibleTypeLoadModal(false);
              }}
              typeStyle={2}
            >
              <div className="icons">
                <Icons src={orderNuts} />
                <Icons src={orderCups} />
              </div>
              <div className="title">Pedidos de Secos</div>
              <div className="subTitle">
                Acompanhamentos, utensílios e colecionáveis
              </div>
              <div className="divText">
                <ul>
                  <li>Pedidos devem ser realizados observando sua quinzena;</li>
                  <li>
                    Disponível <b>todos os dias</b>.
                  </li>
                  <li>
                    É necessário entrar em contato com a <b>logística</b> e
                    avisar quando o pedido for realizado de{" "}
                    <b>segunda a sexta</b>.
                  </li>
                </ul>
              </div>
            </ButtonCard>
          </ModalDivButtons>
        </>
      </ModalTypeLoad>

      <ModalBlockStore
        visibleModalBlockStore={visibleModalBlockStore}
        setvisibleModalBlockStore={setvisibleModalBlockStore}
        blockStore={changeDefaulterStatus}
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
      />

      <ModalCreateEditOrder
        order={orderToEdit}
        setOrderToEdit={setOrderToEdit}
        visible={visibleModalCreateEditOrder}
        setVisible={setVisibleModalCreateEditOrder}
        setShouldSearch={setShouldSearch}
        store={orderToEdit ? orderToEdit?.store_id : store}
        categories={categories}
        user={user}
        minOrder={minOrder}
        typeOfLoad={typeOfLoad}
        setTypeOfLoad={setTypeOfLoad}
        countryPY={true}
        servicePercentage={0}
        productPercentage={null}
      />
    </PageContainer>
  );
};

export default ListOrderPY;
